import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";
import LoadingIndicator from "./LoadingIndicator";
import AppHeader from "./AppHeader";
import Toast from "./Toast";
import AnnouncementBanner from "./AnnouncementBanner";
import { GoogleAnalyticsPageTracker } from "./GoogleAnalyticsPageTracker";
import withErrorBoundary from "./error/withErrorBoundary";
import {
  MAP,
  ACCOUNTS,
  CHARTS,
  DYNAMIC_PRICE,
  INVENTORY,
  LISTINGS,
  OCCUPANCY,
  RATES,
  REVENUE,
  SEASONALITY,
  TREND,
} from "../constants/pages";
import ContactUs from "./ContactUs";
import FAQ from "./FAQ";
import AboutUs from "./AboutUs";
import PrivacyAndCookiePolicy from "./PrivacyAndCookiePolicy";
import TermsOfService from "./TermsOfService";
import AirbnbCalculator from "./calculator/AirbnbCalculator";
import CalculatorPromo from "./calculator/CalculatorPromo";
import MaintenancePage from "./MaintenancePage";

const MAINTENANCE_MODE = false;

// Lazy load components
const Home = lazy(() => import("./Home"));
const Atlas = lazy(() => import("./Atlas"));
const BlogIndex = lazy(() => import("./blog/BlogIndex"));
const BlogPost = lazy(() => import("./blog/BlogPost"));
const DynamicPrice = lazy(() => import("./DynamicPrice"));
const ChannelAccounts = lazy(() => import("./dynamic-pricing/ChannelAccounts"));
const AirbnbMap = lazy(() => import("./AirbnbMap"));
const InventoryCharts = lazy(() => import("./charts/InventoryCharts"));
const RevenueCharts = lazy(() => import("./charts/RevenueCharts"));
const OccupancyCharts = lazy(() => import("./charts/OccupancyCharts"));
const RatesCharts = lazy(() => import("./charts/RatesCharts"));
const SeasonalityCharts = lazy(() => import("./charts/SeasonalityCharts"));
const TrendCharts = lazy(() => import("./charts/TrendCharts"));
const PricingDashboard = lazy(() =>
  import("./dynamic-pricing/PricingDashboard")
);
const ListingPricing = lazy(() => import("./dynamic-pricing/ListingPricing"));
const AuthCallback = lazy(() => import("./auth/AuthCallback"));

const RoutedApp = () => {
  const { isAuthenticated } = useAuth();
  const atlasReady = useSelector((state) => state.airbnbListings.ready);
  const dynamicPricingReady = useSelector(
    (state) => state.dynamicPricing.dashboard.ready
  );

  return (
    <Router>
      <GoogleAnalyticsPageTracker>
        {!MAINTENANCE_MODE ? (
          <>
            <Toast />
            <AnnouncementBanner
              message="🚀 Airbnb Data Portal just launched! Access raw Airbnb datasets to conduct your own analysis, discover market trends and more!"
              id="data_portal_launch_2025"
              linkText="Download Data"
              linkUrl="/data-portal"
              bgColor="linear-gradient(90deg, rgba(41,170,255,1) 0%, rgba(73,101,255,1) 100%)"
              expiryDate="2025-05-01T23:59:59"
            />
            <AppHeader />
          </>
        ) : null}

        <Suspense fallback={<LoadingIndicator ml={3} />}>
          <Routes>
            {MAINTENANCE_MODE ? (
              <>
                {/* When in maintenance mode, all routes go to the MaintenancePage */}
                <Route path="*" element={<MaintenancePage />} />
              </>
            ) : (
              <>
                {/* Add auth callback route */}
                <Route path="/auth/callback" element={<AuthCallback />} />

                {/* Normal routes when not in maintenance mode */}
                <Route path="/atlas" element={<Atlas />}>
                  <Route
                    path={MAP}
                    element={
                      atlasReady ? <AirbnbMap /> : <LoadingIndicator ml={3} />
                    }
                  />

                  <Route
                    path={`${CHARTS}/${INVENTORY}`}
                    element={
                      atlasReady ? (
                        <InventoryCharts />
                      ) : (
                        <LoadingIndicator ml={3} />
                      )
                    }
                  />

                  <Route
                    path={`${CHARTS}/${RATES}`}
                    element={
                      atlasReady ? <RatesCharts /> : <LoadingIndicator ml={3} />
                    }
                  />

                  <Route
                    path={`${CHARTS}/${OCCUPANCY}`}
                    element={
                      atlasReady ? (
                        <OccupancyCharts />
                      ) : (
                        <LoadingIndicator ml={3} />
                      )
                    }
                  />

                  <Route
                    path={`${CHARTS}/${TREND}`}
                    element={
                      atlasReady ? <TrendCharts /> : <LoadingIndicator ml={3} />
                    }
                  />

                  <Route
                    path={`${CHARTS}/${REVENUE}`}
                    element={
                      atlasReady ? (
                        <RevenueCharts />
                      ) : (
                        <LoadingIndicator ml={3} />
                      )
                    }
                  />

                  <Route
                    path={`${DYNAMIC_PRICE}/${LISTINGS}`}
                    element={<DynamicPrice />}
                  >
                    <Route
                      path=""
                      element={
                        dynamicPricingReady ? (
                          <PricingDashboard />
                        ) : (
                          <LoadingIndicator ml="28px" />
                        )
                      }
                    />

                    <Route path=":listingId" element={<ListingPricing />} />
                  </Route>

                  <Route
                    path={`${DYNAMIC_PRICE}/${ACCOUNTS}`}
                    element={<ChannelAccounts />}
                  />
                </Route>

                <Route path="/blog/:slug" element={<BlogPost />} />
                <Route path="/blog" element={<BlogIndex />} />

                <Route path="/" element={<Home />} />

                {/* <Route path="/investment/:query?" element={<Investment />} /> */}

                {/* <Route path="/analyzer/:query?" element={<Analyzer />} /> */}

                {/* <Route path="/settings" element={<Settings />} /> */}
                <Route path="/faq" element={<FAQ />} />
                <Route path="/contact" element={<ContactUs full />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/policy" element={<PrivacyAndCookiePolicy />} />
                <Route path="/tos" element={<TermsOfService />} />

                {/* <Route path="/labeler" element={<Labeler />} /> */}

                <Route path="/calculator" element={<CalculatorPromo />} />
                <Route
                  path="/calculator-worksheet"
                  element={<AirbnbCalculator />}
                />
              </>
            )}
          </Routes>
        </Suspense>
      </GoogleAnalyticsPageTracker>
    </Router>
  );
};

export default withErrorBoundary(RoutedApp);
