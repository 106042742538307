import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import {
  Box,
  Paper,
  Typography,
  useTheme,
  Slider,
  Grid,
  Divider,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  BarChart as ChartIcon,
  TableRows as TableIcon,
} from "@mui/icons-material";
import NumberFormat from "react-number-format";
import withErrorBoundary from "../error/withErrorBoundary";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    // Calculate total value for ROI
    const totalValue = payload.reduce((sum, entry) => sum + entry.value, 0);
    const initialInvestment = payload[3].value; // downPayment is the initial investment
    const roi = ((totalValue - initialInvestment) / initialInvestment) * 100;

    return (
      <Paper sx={{ p: 1.5, backgroundColor: "rgba(255, 255, 255, 0.95)" }}>
        <Box sx={{ mb: 1.5, textAlign: "center" }}>
          <Typography variant="caption" color="text.secondary">
            Year {payload[0].payload.year}
          </Typography>
        </Box>
        <Divider sx={{ mb: 1.5 }} />
        {payload.map((entry, index) => (
          <Box key={index} mb={index < payload.length - 1 ? 1 : 0}>
            <Typography variant="caption" color="text.secondary">
              {entry.name}
            </Typography>
            <Typography variant="body2" fontWeight="medium">
              <NumberFormat
                value={entry.value}
                displayType="text"
                thousandSeparator
                prefix="$"
              />
            </Typography>
          </Box>
        ))}
        <Box
          sx={{
            mt: 2,
            pt: 1.5,
            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="caption"
              sx={{ color: "primary.main", fontWeight: "medium" }}
            >
              Return on Investment
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "primary.main", fontWeight: "bold" }}
            >
              {roi.toFixed(1)}%
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      payload: PropTypes.shape({
        year: PropTypes.number,
      }),
    })
  ),
};

CustomTooltip.defaultProps = {
  active: false,
  payload: [],
};

const InvestmentProjection = ({ data, onAppreciationChange }) => {
  const theme = useTheme();
  const DEFAULT_PROPERTY_APPRECIATION = 3;
  const DEFAULT_REVENUE_APPRECIATION = 3;
  const [viewMode, setViewMode] = useState("chart");

  const [propertyAppreciation, setPropertyAppreciation] = useState(
    DEFAULT_PROPERTY_APPRECIATION
  );
  const [revenueAppreciation, setRevenueAppreciation] = useState(
    DEFAULT_REVENUE_APPRECIATION
  );

  const calculateProjectionData = (financialDetails) => {
    const projectionYears = 10;
    const projectionData = [];

    // Initial values
    const purchasePrice = financialDetails.purchasePrice || 0;
    const downPaymentAmount = financialDetails.downPaymentAmount || 0;
    const monthlyRevenue = financialDetails.rentalRevenue || 0;
    const loanAmount = financialDetails.loanAmount || 0;
    const interestRate = (financialDetails.interestRate || 0) / 100;
    const loanTermYears = financialDetails.loanTerm || 30;

    // Monthly expenses
    const monthlyExpenses =
      (financialDetails.managementFeeAmount || 0) +
      (financialDetails.channelFeeAmount || 0) +
      (financialDetails.propertyTaxAmount || 0) / 12 + // Convert annual to monthly
      (financialDetails.insurance || 0) +
      (financialDetails.hoaFees || 0) +
      (financialDetails.utilities || 0) +
      (financialDetails.maintenance || 0) +
      (financialDetails.otherExpensesAmount || 0);

    // Calculate monthly mortgage payment if using a loan
    let monthlyMortgage = 0;
    if (!financialDetails.isCashOnly && financialDetails.useLoan) {
      const monthlyRate = interestRate / 12;
      const numberOfPayments = loanTermYears * 12;
      if (monthlyRate > 0) {
        monthlyMortgage =
          (loanAmount * monthlyRate * (1 + monthlyRate) ** numberOfPayments) /
          ((1 + monthlyRate) ** numberOfPayments - 1);
      }
    }

    // Calculate base annual profit (before appreciation)
    const baseAnnualProfit =
      (monthlyRevenue - monthlyExpenses - monthlyMortgage) * 12;

    for (let year = 1; year <= projectionYears; year++) {
      // Calculate cumulative property appreciation
      const propertyValue =
        purchasePrice * (1 + propertyAppreciation / 100) ** year;
      const propertyAppreciationValue = Math.round(
        propertyValue - purchasePrice
      );

      // Calculate cumulative revenue appreciation
      const appreciatedAnnualProfit = Math.round(
        baseAnnualProfit * (1 + revenueAppreciation / 100) ** year
      );

      // Calculate principal paydown
      let principalPaydown = 0;
      if (!financialDetails.isCashOnly && financialDetails.useLoan) {
        const monthlyRate = interestRate / 12;
        const totalPayments = year * 12;
        const remainingBalance =
          loanAmount *
          (((1 + monthlyRate) ** (loanTermYears * 12) -
            (1 + monthlyRate) ** totalPayments) /
            ((1 + monthlyRate) ** (loanTermYears * 12) - 1));
        principalPaydown = Math.round(loanAmount - remainingBalance);
      }

      projectionData.push({
        year,
        annualProfit: appreciatedAnnualProfit,
        propertyAppreciation: propertyAppreciationValue,
        principalPaydown,
        downPayment: Math.round(downPaymentAmount),
      });
    }

    return projectionData;
  };

  const handlePropertyAppreciationChange = (event, newValue) => {
    setPropertyAppreciation(newValue);
    // Only property appreciation affects main financials
    onAppreciationChange?.({ property: newValue });
  };

  const handleRevenueAppreciationChange = (event, newValue) => {
    // Revenue appreciation only affects local projection
    setRevenueAppreciation(newValue);
  };

  const handleReset = () => {
    setPropertyAppreciation(DEFAULT_PROPERTY_APPRECIATION);
    setRevenueAppreciation(DEFAULT_REVENUE_APPRECIATION);
    // Only property appreciation affects main financials
    onAppreciationChange?.({ property: DEFAULT_PROPERTY_APPRECIATION });
  };

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  // Get financial details from parent component's data prop
  const projectionData = React.useMemo(() => {
    if (!data || !data[0]) return [];
    return calculateProjectionData(data[0]);
  }, [data, propertyAppreciation, revenueAppreciation]);

  const renderProjectionTable = (tableData) => {
    return (
      <TableContainer sx={{ maxHeight: 400, mt: 3 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: theme.palette.primary.light,
                }}
              >
                Year
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: theme.palette.success.light,
                }}
              >
                Annual Profit
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: theme.palette.info.light,
                }}
              >
                Property Appreciation
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: theme.palette.warning.light,
                }}
              >
                Principal Pay-down
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: theme.palette.error.light,
                }}
              >
                Down Payment
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: theme.palette.primary.light,
                }}
              >
                Total Return
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: theme.palette.primary.light,
                }}
              >
                ROI
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => {
              const totalValue =
                row.annualProfit +
                row.propertyAppreciation +
                row.principalPaydown;
              const roi = (totalValue / row.downPayment) * 100;

              return (
                <TableRow key={row.year} hover>
                  <TableCell>Year {row.year}</TableCell>
                  <TableCell align="right">
                    <NumberFormat
                      value={row.annualProfit}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <NumberFormat
                      value={row.propertyAppreciation}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <NumberFormat
                      value={row.principalPaydown}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <NumberFormat
                      value={row.downPayment}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <NumberFormat
                      value={totalValue}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                    />
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      fontWeight: "medium",
                      color: theme.palette.primary.main,
                    }}
                  >
                    {roi.toFixed(1)}%
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Paper
      sx={{
        p: 3,
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography variant="h6" fontWeight="medium">
          Return on Investment
        </Typography>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewModeChange}
            size="small"
          >
            <Tooltip title="Chart View" arrow>
              <ToggleButton value="chart" aria-label="chart view">
                <ChartIcon />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Table View" arrow>
              <ToggleButton value="table" aria-label="table view">
                <TableIcon />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
          <Button
            variant="outlined"
            size="small"
            onClick={handleReset}
            sx={{
              textTransform: "none",
              minWidth: "unset",
              px: 2,
            }}
          >
            Reset
          </Button>
        </Box>
      </Box>

      <Grid container spacing={4} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Property Appreciation
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Slider
              value={propertyAppreciation}
              onChange={handlePropertyAppreciationChange}
              min={0}
              max={20}
              step={0.5}
              sx={{
                "& .MuiSlider-track": {
                  backgroundColor: theme.palette.info.main,
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: theme.palette.info.main,
                  height: 16,
                  width: 16,
                  border: "2px solid currentColor",
                },
              }}
            />
            <Typography variant="body2" sx={{ ml: 2, minWidth: 35 }}>
              {propertyAppreciation}%
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Revenue Appreciation
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Slider
              value={revenueAppreciation}
              onChange={handleRevenueAppreciationChange}
              min={0}
              max={20}
              step={0.5}
              sx={{
                "& .MuiSlider-track": {
                  backgroundColor: theme.palette.success.main,
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: theme.palette.success.main,
                  height: 16,
                  width: 16,
                  border: "2px solid currentColor",
                },
              }}
            />
            <Typography variant="body2" sx={{ ml: 2, minWidth: 35 }}>
              {revenueAppreciation}%
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {viewMode === "chart" ? (
        <Box sx={{ width: "100%", height: 400 }}>
          <ResponsiveContainer>
            <AreaChart
              data={projectionData}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="year"
                tickFormatter={(value) => `Y${value}`}
                axisLine={false}
                tickLine={false}
                ticks={[1, 3, 5, 10]}
                domain={[1, 10]}
              />
              <YAxis
                tickFormatter={(value) => `$${value / 1000}k`}
                axisLine={false}
                tickLine={false}
              />
              <RechartsTooltip content={<CustomTooltip />} />
              <Legend />
              <Area
                type="monotone"
                dataKey="annualProfit"
                stackId="1"
                stroke={theme.palette.success.main}
                fill={theme.palette.success.light}
                name="Annual Profit"
              />
              <Area
                type="monotone"
                dataKey="propertyAppreciation"
                stackId="1"
                stroke={theme.palette.info.main}
                fill={theme.palette.info.light}
                name="Property Appreciation"
              />
              <Area
                type="monotone"
                dataKey="principalPaydown"
                stackId="1"
                stroke={theme.palette.warning.main}
                fill={theme.palette.warning.light}
                name="Principal Pay-down"
              />
              <Area
                type="monotone"
                dataKey="downPayment"
                stackId="1"
                stroke={theme.palette.error.main}
                fill={theme.palette.error.light}
                name="Down Payment"
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      ) : (
        renderProjectionTable(projectionData)
      )}
    </Paper>
  );
};

InvestmentProjection.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number.isRequired,
      profit: PropTypes.number.isRequired,
      propertyAppreciation: PropTypes.number.isRequired,
      principalPaydown: PropTypes.number.isRequired,
      downPayment: PropTypes.number.isRequired,
    })
  ).isRequired,
  onAppreciationChange: PropTypes.func,
};

InvestmentProjection.defaultProps = {
  onAppreciationChange: () => {},
};

export default withErrorBoundary(InvestmentProjection);
