import React from "react";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const HeroSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: { xs: 4, md: 8 },
      }}
    >
      <Container maxWidth="lg" disableGutters>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          component="h1"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            mb: 2,
          }}
        >
          Airbnb Revenue Calculator
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            maxWidth: "800px",
            mx: "auto",
            px: 2,
            color: "text.secondary",
            fontSize: { xs: "14px", md: "16px" },
          }}
        >
          Make data-driven investment decisions with our advanced Airbnb
          calculator. Get accurate revenue projections, occupancy rates, and ROI
          analysis based on real market data from millions of properties.
        </Typography>
      </Container>
    </Box>
  );
};

export default HeroSection;
