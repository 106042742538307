// Form options constants for reuse across components

export const INQUIRY_TYPES = {
  GENERAL: "General",
  DATA_REQUEST: "Data Request",
  FEATURE_REQUEST: "Feature Request",
  BUG_REPORT: "Bug Report",
};

export const INQUIRY_TYPES_ARRAY = Object.values(INQUIRY_TYPES);

export const USE_CASE_TYPES = {
  PERSONAL: "Personal",
  BUSINESS: "Business",
};

export const USE_CASE_TYPES_ARRAY = Object.values(USE_CASE_TYPES);
