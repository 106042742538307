import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Box,
  Paper,
  Typography,
  Slider,
  Stack,
  Button,
  TextField,
  Grid,
  InputAdornment,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import NumberFormat from "react-number-format";
import withErrorBoundary from "../error/withErrorBoundary";

const months = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Paper sx={{ p: 1.5, backgroundColor: "rgba(255, 255, 255, 0.95)" }}>
        <Typography variant="body2" fontWeight="medium">
          <NumberFormat
            value={payload[0].value}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {payload[0].payload.month}
        </Typography>
      </Paper>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      payload: PropTypes.shape({
        month: PropTypes.string,
      }),
    })
  ),
};

CustomTooltip.defaultProps = {
  active: false,
  payload: [],
};

function OccupancyRateField({ value, onChange }) {
  return (
    <NumberFormat
      value={value}
      suffix="%"
      customInput={TextField}
      decimalScale={0}
      allowNegative={false}
      fullWidth
      size="small"
      onValueChange={(values) => {
        onChange(null, values.floatValue);
      }}
    />
  );
}

OccupancyRateField.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

const StyledNumberField = styled(TextField)({
  "& input[type=number]": {
    MozAppearance: "textfield",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
});

const PropertyVariables = ({
  data,
  occupancyRate,
  avgDailyRate,
  onOccupancyChange,
  onDailyRateChange,
  predictedOccupancy,
  predictedDailyRate,
  comparables,
  onReset,
}) => {
  const theme = useTheme();

  // Calculate max daily rate from comparables
  const maxDailyRate = useMemo(() => {
    if (!comparables || !comparables.length) return 1000; // Default fallback
    const max = Math.max(...comparables.map((comp) => comp.averageDailyRate));
    // Round up to nearest 100 to give some buffer
    return Math.ceil(max / 100) * 100;
  }, [comparables]);

  // Calculate percentiles based on actual comparables data
  const calculatePercentile = (value, metric) => {
    if (!comparables || !comparables.length) return 50;

    // Sort values in ascending order
    const sortedValues = [...comparables]
      .map((comp) =>
        metric === "occupancy"
          ? comp.occupancyRate * 100
          : comp.averageDailyRate
      )
      .sort((a, b) => a - b);

    // Find position of current value
    const position = sortedValues.findIndex((v) => value <= v);

    // If value is higher than all comparables
    if (position === -1) return 99;

    // Calculate percentile (100 - percentage to represent "top X%")
    const percentile = 100 - (position / sortedValues.length) * 100;

    // Round to nearest integer and ensure between 1-99
    return Math.min(99, Math.max(1, Math.round(percentile)));
  };

  // Calculate percentiles whenever values change
  const currentOccupancyPercentile = useMemo(
    () => calculatePercentile(occupancyRate, "occupancy"),
    [occupancyRate, comparables]
  );

  const currentDailyRatePercentile = useMemo(
    () => calculatePercentile(avgDailyRate, "dailyRate"),
    [avgDailyRate, comparables]
  );

  const handleDailyRateInput = (event) => {
    const value = Math.min(1000000000, Math.max(1, Number(event.target.value)));
    onDailyRateChange(null, value);
  };

  return (
    <Paper
      sx={{
        p: 3,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Box>
          <Typography variant="h6">Variables</Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
            AirROI estimates <strong>{Math.round(predictedOccupancy)}</strong>%
            occupancy and $<strong>{Math.round(predictedDailyRate)}</strong>{" "}
            daily rate based on market median metrics. Customize these values to
            match your property's specific offerings.
          </Typography>
        </Box>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            // Update the values to predictions
            onOccupancyChange(null, predictedOccupancy);
            onDailyRateChange(null, predictedDailyRate);
            // Call the onReset callback to reset user adjustment tracking
            if (onReset) onReset();
          }}
          sx={{
            textTransform: "none",
            minWidth: "unset",
            px: 2,
          }}
        >
          Reset
        </Button>
      </Box>

      <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Occupancy Rate
            </Typography>
            <Box display="flex" alignItems="center" gap={1} mb={2}>
              <OccupancyRateField
                value={occupancyRate}
                onChange={onOccupancyChange}
              />
            </Box>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Top {currentOccupancyPercentile}% of comparables
            </Typography>
            <Slider
              value={occupancyRate}
              onChange={onOccupancyChange}
              min={0}
              max={100}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}%`}
              sx={{
                "& .MuiSlider-thumb": {
                  height: 16,
                  width: 16,
                  border: "2px solid currentColor",
                },
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Avg Daily Rate
            </Typography>
            <Box display="flex" alignItems="center" gap={1} mb={2}>
              <StyledNumberField
                fullWidth
                value={avgDailyRate}
                onChange={handleDailyRateInput}
                InputProps={{
                  startAdornment: <Typography component="span">$</Typography>,
                  inputProps: {
                    min: 1,
                    max: 1000000000,
                  },
                }}
                size="small"
              />
            </Box>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Top {currentDailyRatePercentile}% of comparables
            </Typography>
            <Slider
              value={avgDailyRate}
              onChange={onDailyRateChange}
              min={0}
              max={maxDailyRate}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `$${value}`}
              sx={{
                "& .MuiSlider-thumb": {
                  height: 16,
                  width: 16,
                  border: "2px solid currentColor",
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box display="flex" mb={4}>
        <Typography variant="h6" fontWeight="medium">
          Seasonality
        </Typography>
      </Box>

      <Box sx={{ width: "100%", height: 300, mb: 4 }}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="month"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12 }}
              tickFormatter={(value) => `$${value / 1000}k`}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="revenue"
              fill={theme.palette.primary.main}
              radius={[4, 4, 0, 0]}
              maxBarSize={25}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

PropertyVariables.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string,
      revenue: PropTypes.number,
      profit: PropTypes.number,
    })
  ).isRequired,
  occupancyRate: PropTypes.number.isRequired,
  avgDailyRate: PropTypes.number.isRequired,
  onOccupancyChange: PropTypes.func.isRequired,
  onDailyRateChange: PropTypes.func.isRequired,
  predictedOccupancy: PropTypes.number.isRequired,
  predictedDailyRate: PropTypes.number.isRequired,
  onReset: PropTypes.func,
  comparables: PropTypes.arrayOf(
    PropTypes.shape({
      occupancyRate: PropTypes.number,
      averageDailyRate: PropTypes.number,
    })
  ),
};

PropertyVariables.defaultProps = {
  comparables: [],
};

export default withErrorBoundary(PropertyVariables);
