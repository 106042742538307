import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  Typography,
  TextField,
  Box,
  Button,
  Container,
  useMediaQuery,
  MenuItem,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SEO from "./SEO";
import withErrorBoundary from "./error/withErrorBoundary";

// Assuming you have a list of country codes
import countryCodes from "../util/countryCodes"; // You need to provide this list
import { submitForm } from "../actions/formsActions";
import {
  INQUIRY_TYPES,
  INQUIRY_TYPES_ARRAY,
  USE_CASE_TYPES,
  USE_CASE_TYPES_ARRAY,
} from "../constants/formOptions";

const styles = {
  comptact: {
    py: 2,
    px: 4,
    mb: { xs: 4, md: 0 },
  },
  container: {
    mx: { xs: 2, sm: 4, md: 10, lg: 20 },
    my: { xs: 8, md: 16 },
  },
  header: {
    textAlign: "center",
    mb: { xs: 4, md: 8 },
  },
  formContainer: {
    maxWidth: "800px",
    mx: "auto",
    mt: { xs: 4, md: 8 },
  },
  textField: {
    mb: 2,
    width: "100%",
  },
  submitButton: {
    mt: 2,
    textTransform: "none",
  },
};

const ContactUs = ({ full = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const blankState = {
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    countryCode: "+1",
    phoneNumber: "",
    useCase: "",
    category: "",
    message: "",
  };

  const [formData, setFormData] = useState(blankState);

  // Parse URL params on component mount to pre-fill the inquiry type
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const inquiryType = urlParams.get("inquery-type");

    if (inquiryType) {
      // Find matching inquiry type from constants
      const validInquiry = INQUIRY_TYPES_ARRAY.find(
        (type) => type.toLowerCase() === inquiryType.toLowerCase()
      );

      if (validInquiry) {
        setFormData((prevData) => ({
          ...prevData,
          category: validInquiry,
        }));
      }
    }
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(submitForm("contact_us", formData));
    setFormData(blankState);
  };

  return (
    <>
      {full && (
        <SEO
          title="Contact Us"
          description="Get in touch with AirROI's support team for help with Airbnb analytics, market research, revenue calculator, or dynamic pricing solutions."
          keywords="contact AirROI, support, customer service, help desk, Airbnb analytics support"
          type="website"
          url="https://www.airroi.com/contact"
          canonical="https://www.airroi.com/contact"
        />
      )}
      <Box sx={full ? styles.container : styles.comptact}>
        <Container maxWidth="xl" disableGutters>
          <Box sx={styles.header}>
            {full ? (
              <Typography variant={isMobile ? "h5" : "h4"} sx={{ mb: 2 }}>
                Contact Us
              </Typography>
            ) : (
              <Typography
                sx={{
                  my: 2,
                  mt: 2,
                  mb: { xs: -2, lg: -8 },
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                CONTACT FORM
              </Typography>
            )}
            {full && (
              <Typography
                sx={{
                  fontSize: { xs: 16, md: 18 },
                  maxWidth: "800px",
                  mx: "auto",
                  color: "text.secondary",
                  mb: 2,
                }}
              >
                You can contact us directly at{" "}
                <Link href="mailto:admin@airroi.com" underline="hover">
                  admin@airroi.com
                </Link>
                , or fill out this form and we'll get back to you ASAP!
              </Typography>
            )}
          </Box>

          <Box sx={styles.formContainer}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              mt={-2}
              onSubmit={handleSubmit}
            >
              <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
                <TextField
                  variant="standard"
                  label="First Name"
                  fullWidth
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />
                <TextField
                  variant="standard"
                  label="Last Name"
                  fullWidth
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />
                {!full && (
                  <TextField
                    variant="standard"
                    label="Company Name"
                    fullWidth
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                )}
              </Box>

              {full && (
                <Box sx={{ mb: 2 }}>
                  <TextField
                    variant="standard"
                    label="Company Name"
                    fullWidth
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                </Box>
              )}

              <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
                <TextField
                  variant="standard"
                  label="Email"
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />
                {!full && (
                  <>
                    <TextField
                      select
                      variant="standard"
                      label="Country Code"
                      name="countryCode"
                      value={formData.countryCode}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{
                        style: { fontSize: 14 },
                      }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                    >
                      {countryCodes.map((country) => (
                        <MenuItem key={country.code} value={country.dial_code}>
                          {country.name} ({country.dial_code})
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      variant="standard"
                      label="Phone Number"
                      fullWidth
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                  </>
                )}
              </Box>

              {full && (
                <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
                  <TextField
                    select
                    variant="standard"
                    label="Country Code"
                    name="countryCode"
                    value={formData.countryCode}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      style: { fontSize: 14 },
                    }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  >
                    {countryCodes.map((country) => (
                      <MenuItem key={country.code} value={country.dial_code}>
                        {country.name} ({country.dial_code})
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    variant="standard"
                    label="Phone Number"
                    fullWidth
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                </Box>
              )}

              <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
                <TextField
                  select
                  variant="standard"
                  label="Inquiry Type"
                  fullWidth
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  InputProps={{
                    style: { fontSize: 14 },
                  }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                >
                  {INQUIRY_TYPES_ARRAY.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  variant="standard"
                  label="Is this for personal or business use?"
                  fullWidth
                  name="useCase"
                  value={formData.useCase}
                  onChange={handleChange}
                  InputProps={{
                    style: { fontSize: 14 },
                  }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                >
                  {USE_CASE_TYPES_ARRAY.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  variant="standard"
                  label="How can we help?"
                  multiline
                  rows={full ? 7 : 2}
                  fullWidth
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />
              </Box>
              <Box display="flex" justifyContent="right">
                <Button
                  variant="contained"
                  size="small"
                  type="submit"
                  sx={{
                    textTransform: "none",
                    mt: 2,
                    mr: 0,
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

ContactUs.propTypes = {
  full: PropTypes.bool,
};

ContactUs.defaultProps = {
  full: false,
};

export default withErrorBoundary(ContactUs);
