import React, { useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { GoogleMap, OverlayView, OverlayViewF } from "@react-google-maps/api";
import {
  Box,
  Paper,
  Typography,
  Slider,
  Stack,
  CircularProgress,
  Divider,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AirbnbListingCard from "../AirbnbListingCard";
import { mapStyles } from "../airbnbMapUtil";
import CustomZoomControl from "../CustomZoomControl";
import withErrorBoundary from "../error/withErrorBoundary";

// Helper function to generate ticks based on actual listing positions
const generatePositionalTicks = (listings, tickCount = 5) => {
  const n = listings.length;
  if (n === 0) return [];

  const ticks = [];
  // Calculate positions for evenly spaced ticks
  for (let i = 0; i < tickCount; i++) {
    const position = Math.floor((i * (n - 1)) / (tickCount - 1));
    if (position < n) {
      ticks.push({
        value: listings[position].revenue,
        position: (position / (n - 1)) * 100,
      });
    }
  }

  // Always include the first and last listing
  if (ticks[0]?.value !== listings[0].revenue) {
    ticks.unshift({ value: listings[0].revenue, position: 0 });
  }
  if (ticks[ticks.length - 1]?.value !== listings[n - 1].revenue) {
    ticks.push({ value: listings[n - 1].revenue, position: 100 });
  }

  return ticks;
};

const formatRevenue = (value) => {
  return `$${Math.round(value / 1000)}k`;
};

const PropertyComparables = ({ comparables }) => {
  const theme = useTheme();
  const mapRef = useRef(null);
  const [selectedListing, setSelectedListing] = useState(null);
  const [hoveredListing, setHoveredListing] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(12);
  const [showAllListings, setShowAllListings] = useState(false);

  // Calculate min and max revenue from actual listings
  const revenueRange = useMemo(() => {
    const minRevenue = Math.floor(
      Math.min(...comparables.map((l) => l.revenue))
    );
    const maxRevenue = Math.ceil(
      Math.max(...comparables.map((l) => l.revenue))
    );
    return [minRevenue, maxRevenue];
  }, [comparables]);

  const [currentRevenueRange, setCurrentRevenueRange] = useState(revenueRange);

  // Sort listings by revenue for the bar chart
  const sortedListings = useMemo(
    () => [...comparables].sort((a, b) => a.revenue - b.revenue),
    [comparables]
  );

  // Generate tick values based on actual listing positions
  const tickValues = useMemo(
    () => generatePositionalTicks(sortedListings),
    [sortedListings]
  );

  // Calculate center from actual listings
  const center = useMemo(() => {
    const sumLat = comparables.reduce((sum, l) => sum + l.latitude, 0);
    const sumLng = comparables.reduce((sum, l) => sum + l.longitude, 0);

    return {
      lat: sumLat / comparables.length,
      lng: sumLng / comparables.length,
    };
  }, [comparables]);

  // Filter listings based on revenue range
  const filteredListings = useMemo(() => {
    return comparables.filter(
      (listing) =>
        listing.revenue >= currentRevenueRange[0] &&
        listing.revenue <= currentRevenueRange[1]
    );
  }, [currentRevenueRange, comparables]);

  const handleMapLoad = (mapInstance) => {
    mapRef.current = mapInstance;
    setZoomLevel(mapInstance.getZoom());

    mapInstance.addListener("zoom_changed", () => {
      setZoomLevel(mapInstance.getZoom());
    });
  };

  const handleRevenueRangeChange = (event, newValue) => {
    setCurrentRevenueRange(newValue);
  };

  const renderMarker = (listing) => {
    const isActive =
      selectedListing?.listingId === listing.listingId ||
      hoveredListing?.listingId === listing.listingId;

    return (
      <OverlayViewF
        key={listing.listingId}
        position={{ lat: listing.latitude, lng: listing.longitude }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <Box
          onClick={() => setSelectedListing(listing)}
          onMouseEnter={() => setHoveredListing(listing)}
          onMouseLeave={() => setHoveredListing(null)}
          sx={{
            width: "18px",
            height: "18px",
            backgroundColor: isActive ? theme.palette.primary.main : "#FF5A5F",
            borderRadius: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
            border: "2px solid white",
            boxShadow: "0 2px 4px rgba(0,0,0,0.18)",
            transition: "transform 0.2s ease-in-out",
            "&:hover": {
              transform: "translate(-50%, -50%) scale(1.1)",
            },
          }}
        />
      </OverlayViewF>
    );
  };

  return (
    <Stack spacing={4}>
      <Paper sx={{ p: 3, width: "100%" }}>
        <Typography variant="h6" gutterBottom>
          Airbnb Comparables
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Optimize your pricing and occupancy rates using market data from
          similar properties in your area.
        </Typography>

        {comparables.length >= 10 ? (
          <>
            {/* Revenue Range Slider */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Revenue per year
              </Typography>
              <Box sx={{ px: 1 }}>
                <Slider
                  value={currentRevenueRange}
                  onChange={handleRevenueRangeChange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={formatRevenue}
                  min={revenueRange[0]}
                  max={revenueRange[1]}
                  sx={{
                    "& .MuiSlider-thumb": {
                      height: 16,
                      width: 16,
                      border: "2px solid currentColor",
                      "&:hover": {
                        boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
                      },
                    },
                    "& .MuiSlider-track": {
                      height: 4,
                    },
                    "& .MuiSlider-rail": {
                      height: 4,
                      opacity: 0.5,
                      backgroundColor: "#bfbfbf",
                    },
                    "& .MuiSlider-mark": {
                      backgroundColor: "#bfbfbf",
                      height: 8,
                      width: 1,
                      "&.MuiSlider-markActive": {
                        opacity: 1,
                        backgroundColor: "currentColor",
                      },
                    },
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1,
                  }}
                >
                  <Typography variant="caption" color="text.secondary">
                    {formatRevenue(revenueRange[0])}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {formatRevenue(revenueRange[1])}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Revenue Bar Chart */}
            <Box
              sx={{
                height: "140px",
                position: "relative",
                mt: 4,
              }}
            >
              {/* X-axis ticks */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: "40px",
                  borderTop: "1px solid #e0e0e0",
                  display: "flex",
                }}
              >
                {tickValues.map((tick, index) => {
                  const isFirst = index === 0;
                  const isLast = index === tickValues.length - 1;

                  let transformValue = "translateX(-50%)";
                  if (isFirst) transformValue = "translateX(0)";
                  if (isLast) transformValue = "translateX(-100%)";

                  return (
                    <Box
                      key={tick.value}
                      sx={{
                        position: "absolute",
                        left: `${tick.position}%`,
                        top: 0,
                        height: "8px",
                        width: "1px",
                        backgroundColor: "#e0e0e0",
                        transform: "translateX(-50%)",
                      }}
                    >
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{
                          position: "absolute",
                          top: 16,
                          left: "50%",
                          transform: transformValue,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {formatRevenue(tick.value)}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>

              {/* Bars */}
              <Box
                sx={{
                  height: "100px",
                  display: "flex",
                  alignItems: "flex-end",
                  gap: "2px",
                }}
              >
                {sortedListings.map((listing) => (
                  <Box
                    key={listing.listingId}
                    sx={{
                      flex: 1,
                      height: "100%",
                      position: "relative",
                      cursor: "pointer",
                      opacity:
                        listing.revenue >= currentRevenueRange[0] &&
                        listing.revenue <= currentRevenueRange[1]
                          ? 1
                          : 0.3,
                      "&:hover": {
                        "& > div": {
                          backgroundColor: theme.palette.primary.main,
                        },
                      },
                    }}
                    onMouseEnter={() => setHoveredListing(listing)}
                    onMouseLeave={() => setHoveredListing(null)}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor:
                          hoveredListing?.listingId === listing.listingId
                            ? theme.palette.primary.main
                            : "#000",
                        height: `${(listing.revenue / revenueRange[1]) * 100}%`,
                        transition: "background-color 0.2s",
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        ) : null}

        {/* Google Map with Airbnb Listings */}
        <Box
          sx={{
            height: "400px",
            width: "100%",
            position: "relative",
            borderRadius: "4px",
            overflow: "hidden",
            mt: 3,
          }}
        >
          <GoogleMap
            id="google-map-comparables"
            mapContainerStyle={{
              height: "100%",
              width: "100%",
            }}
            zoom={zoomLevel}
            center={center}
            onLoad={handleMapLoad}
            options={{
              styles: mapStyles,
              gestureHandling: "greedy",
              mapTypeControl: false,
              fullscreenControl: false,
              streetViewControl: false,
              zoomControl: false,
              clickableIcons: false,
            }}
          >
            {filteredListings.map(renderMarker)}

            <CustomZoomControl
              onZoomIn={() =>
                mapRef.current?.setZoom((mapRef.current?.getZoom() || 12) + 1)
              }
              onZoomOut={() =>
                mapRef.current?.setZoom((mapRef.current?.getZoom() || 12) - 1)
              }
            />

            {/* Listing Card Overlay */}
            {hoveredListing && (
              <OverlayViewF
                position={{
                  lat: hoveredListing.latitude,
                  lng: hoveredListing.longitude,
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <Box
                  sx={{
                    width: "250px",
                    position: "absolute",
                    left: "-125px",
                    bottom: "20px",
                  }}
                >
                  <AirbnbListingCard
                    listing={hoveredListing}
                    listingDetail={{
                      ...hoveredListing,
                      detail: true,
                      daysOccupied: Math.round(
                        hoveredListing.occupancyRate * 365
                      ),
                    }}
                    isSelected={false}
                    isMapOverlay
                    setSelectedListingIdx={() => {
                      setHoveredListing(null);
                    }}
                    fetchDetails={false}
                  />
                </Box>
              </OverlayViewF>
            )}
          </GoogleMap>
        </Box>

        {/* Listings Table */}
        <Box sx={{ mt: 4, px: 3 }}>
          <Box sx={{ overflowX: "auto" }}>
            <Box
              sx={{
                display: "table",
                minWidth: "100%",
                borderCollapse: "collapse",
              }}
            >
              {/* Table Header */}
              <Box sx={{ display: "table-header-group" }}>
                <Box sx={{ display: "table-row" }}>
                  <Box
                    sx={{
                      display: "table-cell",
                      py: 2,
                      fontWeight: "bold",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                    }}
                  >
                    Airbnb Name
                  </Box>
                  <Box
                    sx={{
                      display: "table-cell",
                      py: 2,
                      fontWeight: "bold",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                      textAlign: "right",
                    }}
                  >
                    Revenue
                  </Box>
                  <Box
                    sx={{
                      display: "table-cell",
                      py: 2,
                      fontWeight: "bold",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                      textAlign: "right",
                    }}
                  >
                    Per Night
                  </Box>
                  <Box
                    sx={{
                      display: "table-cell",
                      py: 2,
                      fontWeight: "bold",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                      textAlign: "right",
                    }}
                  >
                    Occupancy
                  </Box>
                  <Box
                    sx={{
                      display: "table-cell",
                      py: 2,
                      fontWeight: "bold",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                      textAlign: "right",
                    }}
                  >
                    Beds
                  </Box>
                  <Box
                    sx={{
                      display: "table-cell",
                      py: 2,
                      fontWeight: "bold",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                      textAlign: "right",
                    }}
                  >
                    Baths
                  </Box>
                  <Box
                    sx={{
                      display: "table-cell",
                      py: 2,
                      fontWeight: "bold",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                      textAlign: "right",
                    }}
                  >
                    Guests
                  </Box>
                </Box>
              </Box>
              {/* Table Body */}
              <Box sx={{ display: "table-row-group" }}>
                {(showAllListings
                  ? filteredListings
                  : filteredListings.slice(0, 10)
                ).map((listing) => (
                  <Box
                    key={listing.listingId}
                    sx={{
                      display: "table-row",
                      cursor: "pointer",
                      backgroundColor:
                        selectedListing?.listingId === listing.listingId ||
                        hoveredListing?.listingId === listing.listingId
                          ? "rgba(0, 0, 0, 0.04)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                    }}
                    onMouseEnter={() => setHoveredListing(listing)}
                    onMouseLeave={() => setHoveredListing(null)}
                    onClick={() => setSelectedListing(listing)}
                  >
                    <Box
                      sx={{
                        display: "table-cell",
                        py: 2,
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        color: "primary.main",
                        maxWidth: "300px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {listing.listingName}
                    </Box>
                    <Box
                      sx={{
                        display: "table-cell",
                        py: 2,
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        textAlign: "right",
                      }}
                    >
                      ${listing.revenue.toLocaleString()}
                    </Box>
                    <Box
                      sx={{
                        display: "table-cell",
                        py: 2,
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        textAlign: "right",
                      }}
                    >
                      ${listing.averageDailyRate.toLocaleString()}
                    </Box>
                    <Box
                      sx={{
                        display: "table-cell",
                        py: 2,
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        textAlign: "right",
                      }}
                    >
                      {Math.round(listing.occupancyRate * 100)}%
                    </Box>
                    <Box
                      sx={{
                        display: "table-cell",
                        py: 2,
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        textAlign: "right",
                      }}
                    >
                      {Math.max(1, listing.bedrooms || 1)}
                    </Box>
                    <Box
                      sx={{
                        display: "table-cell",
                        py: 2,
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        textAlign: "right",
                      }}
                    >
                      {Math.max(1, listing.baths || 1)}
                    </Box>
                    <Box
                      sx={{
                        display: "table-cell",
                        py: 2,
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        textAlign: "right",
                      }}
                    >
                      {Math.max(1, listing.guests || 1)}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>

          {/* Show All Button */}
          {filteredListings.length > 10 && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                variant="text"
                onClick={() => setShowAllListings(!showAllListings)}
                sx={{ textTransform: "none" }}
              >
                {showAllListings
                  ? "Show Less"
                  : `Show All ${filteredListings.length} Listings`}
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </Stack>
  );
};

PropertyComparables.propTypes = {
  comparables: PropTypes.arrayOf(
    PropTypes.shape({
      listingId: PropTypes.string.isRequired,
      listingName: PropTypes.string.isRequired,
      revenue: PropTypes.number.isRequired,
      averageDailyRate: PropTypes.number.isRequired,
      occupancyRate: PropTypes.number.isRequired,
      bedrooms: PropTypes.number.isRequired,
      baths: PropTypes.number.isRequired,
      guests: PropTypes.number.isRequired,
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default withErrorBoundary(PropertyComparables);
