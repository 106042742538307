import React from "react";
import { useAuth } from "react-oidc-context";
import { MenuItem, CircularProgress } from "@mui/material";
import { getSignOutRedirectUrl } from "../../config/cognito";

const AuthButtons = () => {
  const auth = useAuth();

  const handleLogin = () => {
    auth.signinRedirect();
  };

  const handleLogout = () => {
    // First clear the user from local state
    auth.removeUser();

    // Then redirect to Cognito logout endpoint
    window.location.href = getSignOutRedirectUrl();
  };

  if (auth.error) {
    console.error("Auth error:", auth.error);
  }

  // Return MenuItems directly
  return (
    <>
      {auth.isAuthenticated ? (
        <MenuItem onClick={handleLogout}>Log out</MenuItem>
      ) : (
        <MenuItem onClick={handleLogin}>Log in</MenuItem>
      )}
    </>
  );
};

export default AuthButtons;
