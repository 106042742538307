import { API_HOST } from "../constants/bootstrap";

// Action Types
export const GET_CALCULATOR_ESTIMATE = "GET_CALCULATOR_ESTIMATE";
export const SET_CALCULATOR_DATA_READINESS = "SET_CALCULATOR_DATA_READINESS";

// Action Creators
export const getCalculatorEstimate = (result) => ({
  type: GET_CALCULATOR_ESTIMATE,
  payload: result,
});

export const setCalculatorDataReadiness = (result) => ({
  type: SET_CALCULATOR_DATA_READINESS,
  ready: result.ready,
});

// Thunk Action Creator
export const fetchCalculatorPrediction = (params) => {
  return async (dispatch) => {
    dispatch(setCalculatorDataReadiness({ ready: false }));

    const { lat, lng, bedrooms, bathrooms, guests } = params;
    const url = `${API_HOST}/calculator/estimate?lat=${lat}&lng=${lng}&bedrooms=${bedrooms}&baths=${bathrooms}&guests=${guests}`;

    try {
      const response = await fetch(url);
      if (response.ok) {
        const result = await response.json();
        dispatch(getCalculatorEstimate(result));
      }
    } catch (error) {
      console.error("Failed to fetch calculator estimate:", error);
    } finally {
      dispatch(setCalculatorDataReadiness({ ready: true }));
    }
  };
};
