import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  IconButton,
  Collapse,
  Link,
  Paper,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AnnouncementIcon from "@mui/icons-material/Campaign";

const LOCAL_STORAGE_KEY_PREFIX = "airbnb_announcement_dismissed_";

/**
 * AnnouncementBanner displays important announcements at the top of the application.
 *
 * @param {Object} props Component props
 * @param {string} props.message The announcement message to display
 * @param {string} [props.id] Unique identifier for this announcement (for localStorage)
 * @param {string} [props.linkText] Optional link text
 * @param {string} [props.linkUrl] Optional link URL
 * @param {string} [props.bgColor] Optional background color (can be gradient)
 * @param {string} [props.textColor] Optional text color
 * @param {Date|string} [props.expiryDate] Optional expiry date (ISO string or Date object)
 */
const AnnouncementBanner = ({
  message,
  id = "default",
  linkText,
  linkUrl,
  bgColor,
  textColor,
  expiryDate,
}) => {
  const theme = useTheme();
  const storageKey = `${LOCAL_STORAGE_KEY_PREFIX}${id}`;
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    // Check if the announcement has been dismissed
    const isDismissed = localStorage.getItem(storageKey) === "true";

    // Check if the announcement has expired
    let isExpired = false;
    if (expiryDate) {
      const expiryDateTime =
        typeof expiryDate === "string" ? new Date(expiryDate) : expiryDate;

      isExpired = new Date() > expiryDateTime;
    }

    // Only show if not dismissed and not expired
    setIsVisible(!isDismissed && !isExpired);

    // Trigger entrance animation
    if (!isDismissed && !isExpired) {
      setIsAnimating(true);
      const timer = setTimeout(() => {
        setIsAnimating(false);
      }, 800);

      return () => clearTimeout(timer);
    }

    return undefined;
  }, [storageKey, expiryDate]);

  const handleDismiss = () => {
    localStorage.setItem(storageKey, "true");
    setIsVisible(false);
  };

  // Default gradient background if no bgColor provided
  const defaultBgColor =
    bgColor ||
    "linear-gradient(90deg, rgba(25,118,210,1) 0%, rgba(66,165,245,1) 100%)";
  const defaultTextColor = textColor || "#fff";

  return (
    <Collapse in={isVisible}>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          background: defaultBgColor,
          color: defaultTextColor,
          boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
          overflow: "hidden",
          position: "relative",
          borderBottom: "1px solid rgba(255,255,255,0.2)",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "1px",
            background: "rgba(255,255,255,0.3)",
          },
          transform: isAnimating ? "translateY(-100%)" : "translateY(0)",
          animation: isAnimating ? "slideDown 0.5s forwards ease-out" : "none",
          "@keyframes slideDown": {
            "0%": { transform: "translateY(-100%)" },
            "100%": { transform: "translateY(0)" },
          },
        }}
      >
        {/* Decorative elements */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0.1,
            background:
              "radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%)",
            backgroundSize: "120% 120%",
            animation: "pulse 15s infinite ease-in-out",
            "@keyframes pulse": {
              "0%": { backgroundPosition: "0% 0%" },
              "50%": { backgroundPosition: "100% 100%" },
              "100%": { backgroundPosition: "0% 0%" },
            },
          }}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "12px 24px",
            position: "relative",
            zIndex: 2,
          }}
        >
          <AnnouncementIcon
            sx={{
              marginRight: 1.5,
              fontSize: "1.2rem",
              animation: "bounce 2s infinite",
              "@keyframes bounce": {
                "0%, 100%": { transform: "translateY(0)" },
                "50%": { transform: "translateY(-2px)" },
              },
            }}
          />

          <Typography
            variant="body1"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: "center",
              fontWeight: 500,
              fontSize: "0.95rem",
              letterSpacing: "0.01em",
              textShadow: "0 1px 2px rgba(0,0,0,0.1)",
            }}
          >
            {message}
            {linkText && linkUrl && (
              <Link
                href={linkUrl}
                underline="hover"
                sx={{
                  color: "inherit",
                  fontWeight: "bold",
                  ml: 1,
                  position: "relative",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: -2,
                    left: 0,
                    width: "100%",
                    height: "1px",
                    background: "currentColor",
                    opacity: 0.7,
                    transition:
                      "transform 0.2s ease-in-out, opacity 0.2s ease-in-out",
                    transform: "scaleX(0.8)",
                    transformOrigin: "center",
                  },
                  "&:hover::after": {
                    opacity: 1,
                    transform: "scaleX(1)",
                  },
                }}
              >
                {linkText}
              </Link>
            )}
          </Typography>

          <IconButton
            size="small"
            aria-label="dismiss"
            onClick={handleDismiss}
            sx={{
              color: "inherit",
              opacity: 0.8,
              transition: "all 0.2s ease",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                opacity: 1,
                transform: "scale(1.05)",
              },
              "&:active": {
                transform: "scale(0.95)",
              },
            }}
          >
            <CloseIcon fontSize="small" sx={{ fontSize: "1rem" }} />
          </IconButton>
        </Box>
      </Paper>
    </Collapse>
  );
};

AnnouncementBanner.propTypes = {
  message: PropTypes.string.isRequired,
  id: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  expiryDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
};

AnnouncementBanner.defaultProps = {
  id: "default",
  linkText: null,
  linkUrl: null,
  bgColor: null,
  textColor: null,
  expiryDate: null,
};

export default AnnouncementBanner;
