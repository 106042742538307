import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Paper, Typography, Divider } from "@mui/material";
import NumberFormat from "react-number-format";
import PropertyDetails from "./PropertyDetails";
import MetricCard from "./MetricCard";
import withErrorBoundary from "../error/withErrorBoundary";

const PropertyOverview = ({
  propertyDetails,
  onInputChange,
  predictions,
  searchInput,
  handlePlaceSelect,
  handleAddressSearch,
  isLoaded,
  calculatedMetrics,
}) => {
  return (
    <Paper
      sx={{
        p: 3,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h6">Property</Typography>
      </Box>

      <Grid container spacing={4}>
        {/* Left Column - Property Details */}
        <Grid item xs={12} md={5.5}>
          <PropertyDetails
            propertyDetails={propertyDetails}
            onInputChange={onInputChange}
            predictions={predictions}
            searchInput={searchInput}
            handlePlaceSelect={handlePlaceSelect}
            handleAddressSearch={handleAddressSearch}
            isLoaded={isLoaded}
          />
        </Grid>

        {/* Vertical Divider for MD+ screens */}
        <Grid
          item
          md={1}
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              height: "100%",
              borderRightWidth: 1,
              borderColor: "grey.300",
            }}
          />
        </Grid>

        {/* Horizontal Divider for XS-SM screens */}
        <Grid
          item
          xs={12}
          sx={{
            display: { xs: "block", md: "none" },
            my: 3,
          }}
        >
          <Divider sx={{ borderColor: "grey.300" }} />
        </Grid>

        {/* Right Column - Financial Metrics */}
        <Grid item xs={12} md={5.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <MetricCard
                title="Annual Revenue"
                value={
                  <NumberFormat
                    value={
                      calculatedMetrics.annualRevenue ||
                      calculatedMetrics.estimatedRevenue * 12 ||
                      0
                    }
                    displayType="text"
                    thousandSeparator
                    prefix="$"
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MetricCard
                title="Occupancy Rate"
                value={`${Math.round(calculatedMetrics.occupancyRate || 0)}%`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MetricCard
                title="Avg Daily Rate"
                value={
                  <NumberFormat
                    value={calculatedMetrics.averageDailyRate || 0}
                    displayType="text"
                    thousandSeparator
                    prefix="$"
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

PropertyOverview.propTypes = {
  propertyDetails: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  predictions: PropTypes.array.isRequired,
  searchInput: PropTypes.string.isRequired,
  handlePlaceSelect: PropTypes.func.isRequired,
  handleAddressSearch: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool,
  calculatedMetrics: PropTypes.object.isRequired,
};

PropertyOverview.defaultProps = {
  isLoaded: true,
};

export default withErrorBoundary(PropertyOverview);
