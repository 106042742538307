import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import App from "./components/App";
import configureStore from "./store/configureStore";
import AuthProvider from "./components/auth/AuthProvider";
import { defaultStoreValues } from "./store/storeDefaultValues";

// Sentry Note: In development mode, React intentionally remounts the application after an error occurs to help identify issues that might be masked by error boundaries in production. This remounting can cause the error to appear multiple times, and subsequently show up twice in Sentry.
// In production mode we should not see duplicate logging of errors
// ENV is a global variable defined by webpack.DefinePlugin
// eslint-disable-next-line no-undef
if (ENV === "production") {
  Sentry.init({
    telemetry: false,
    dsn: "https://54a3db272b8035263311f30e53356d9b@o4507850327588864.ingest.us.sentry.io/4507850329227264",
    integrations: [
      Sentry.browserTracingIntegration(),
      // TODO: replay currently takes heavy toll on performance, find a way to integrate it without slowing things down in the future
      // Sentry.replayIntegration(),
    ],

    sampleRate: 1.0, // 100% of errors captured (default)

    // Tracing
    tracesSampleRate: 0.0, //  this is for performance metrics, not related to error reporting

    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // because currently the endpoints don't share a common convenient prefix like /api, so we have to manually include them all
    // search { @Path("/ } in Java code
    tracePropagationTargets: [
      "localhost",
      /^https?:\/\/www\.airroi\.com\/(bristleback|auth|dynamic_pricing|typeahead|calculator)/,
    ],
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const store = configureStore(defaultStoreValues);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Provider>
);
