import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Paper,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PercentIcon from "@mui/icons-material/Percent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NumberFormat from "react-number-format";
import { margin } from "@mui/system";
import withErrorBoundary from "../error/withErrorBoundary";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const DualInputGroup = styled(Box)(({ theme }) => ({
  "& .MuiTextField-root": {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
    },
  },
  "& .amount-field .MuiOutlinedInput-root": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  "& .percentage-field .MuiOutlinedInput-root": {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: "-1px", // Overlap borders
  },
}));

const PercentageField = ({
  label,
  value,
  amount,
  onChange,
  onToggle,
  usePercentage,
}) => (
  <StyledTextField
    size="small"
    fullWidth
    label={label}
    value={value}
    onChange={onChange}
    InputProps={{
      endAdornment: (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Tooltip
            title={usePercentage ? "Switch to amount" : "Switch to percentage"}
          >
            <IconButton size="small" onClick={onToggle}>
              {usePercentage ? <AttachMoneyIcon /> : <PercentIcon />}
            </IconButton>
          </Tooltip>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ minWidth: 80, textAlign: "right" }}
          >
            {usePercentage ? `$${amount?.toLocaleString() || 0}` : `${value}%`}
          </Typography>
        </Box>
      ),
    }}
  />
);

PercentageField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  amount: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  usePercentage: PropTypes.bool.isRequired,
};

PercentageField.defaultProps = {
  amount: 0,
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
  "& .MuiAccordionSummary-root": {
    padding: theme.spacing(0, 2),
    minHeight: "48px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    margin: "8px 0",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
    transition: "all 0.2s ease-in-out",
    border: "1px solid #e0e0e0",
    "&:hover": {
      backgroundColor: "#fafafa",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      transform: "translateY(-1px)",
    },
    "&.Mui-expanded": {
      // backgroundColor: "#f8f9fa",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
      borderBottom: "1px solid #e0e0e0",
      margin: "8px 0 0 0",
    },
  },
  "& .MuiAccordionDetails-root": {
    padding: theme.spacing(3),
    backgroundColor: "#fff",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    borderLeft: "1px solid #e0e0e0",
    borderRight: "1px solid #e0e0e0",
    borderBottom: "1px solid #e0e0e0",
    boxShadow: "0 4px 8px rgba(0,0,0,0.05)",
  },
}));

const SummaryRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  "& .MuiTypography-root": {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.primary.main,
  },
}));

const FieldLabel = ({ label, tooltip }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, mb: 0.5 }}>
    <Typography variant="subtitle2" color="text.secondary">
      {label}
    </Typography>
    <Tooltip title={tooltip}>
      <InfoOutlinedIcon
        sx={{ fontSize: 16, color: "text.secondary", cursor: "help" }}
      />
    </Tooltip>
  </Box>
);

FieldLabel.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

const FinancialDetails = ({
  financialDetails,
  onFinancialDetailsChange,
  disableCard,
}) => {
  const [inputModes, setInputModes] = useState({
    managementFee: true,
    lodgingTax: true,
    propertyTax: true,
    channelFee: true,
  });

  const [expanded, setExpanded] = useState({
    totalInvestment: false,
    revenue: false,
    expenses: false,
    loan: false,
  });

  const handleInputChange =
    (field) =>
    ({ target: { value } }) => {
      const numericValue = Number(value) || 0;
      onFinancialDetailsChange({
        ...financialDetails,
        [field]: numericValue,
      });

      // Sync purchase price with PropertyDetails immediately
      if (field === "purchasePrice") {
        onFinancialDetailsChange(
          {
            ...financialDetails,
            [field]: numericValue,
          },
          { syncPropertyDetails: true }
        );
      }
    };

  const handlePercentageChange =
    (field) =>
    ({ target: { value } }) => {
      const numericValue = Number(value) || 0;
      const baseValue =
        field === "propertyTax"
          ? financialDetails.purchasePrice
          : financialDetails.rentalRevenue;
      const amount = (numericValue / 100) * baseValue;
      onFinancialDetailsChange({
        ...financialDetails,
        [field]: numericValue,
        [`${field}Amount`]: amount,
      });
    };

  const handleAmountChange =
    (field) =>
    ({ target: { value } }) => {
      const numericValue = Number(value) || 0;
      const baseValue =
        field === "propertyTax"
          ? financialDetails.purchasePrice
          : financialDetails.rentalRevenue;
      const percentage = ((numericValue / baseValue) * 100).toFixed(2);
      onFinancialDetailsChange({
        ...financialDetails,
        [field]: Number(percentage),
        [`${field}Amount`]: numericValue,
      });
    };

  const toggleInputMode = (field) => () => {
    setInputModes((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded((prev) => ({
      ...prev,
      [panel]: isExpanded,
    }));
  };

  const handleChannelFeeAmountChange = ({ target: { value } }) => {
    const numericValue = Number(value) || 0;
    const baseValue = financialDetails.rentalRevenue || 0;
    const percentValue =
      baseValue > 0 ? ((numericValue / baseValue) * 100).toFixed(2) : 0;

    onFinancialDetailsChange({
      ...financialDetails,
      channelFee: Number(percentValue),
      channelFeeAmount: numericValue,
    });
  };

  const handleChannelFeePercentChange = ({ target: { value } }) => {
    const numericValue = Number(value) || 0;
    const baseValue = financialDetails.rentalRevenue || 0;
    const amount = (numericValue / 100) * baseValue;

    onFinancialDetailsChange({
      ...financialDetails,
      channelFee: numericValue,
      channelFeeAmount: Number(amount.toFixed(2)),
    });
  };

  const handleOtherExpensesAmountChange = ({ target: { value } }) => {
    const numericValue = Number(value) || 0;
    const baseValue = financialDetails.rentalRevenue || 0;
    const percentValue =
      baseValue > 0 ? ((numericValue / baseValue) * 100).toFixed(2) : 0;

    onFinancialDetailsChange({
      ...financialDetails,
      otherExpenses: Number(percentValue),
      otherExpensesAmount: numericValue,
    });
  };

  const handleOtherExpensesPercentChange = ({ target: { value } }) => {
    const numericValue = Number(value) || 0;
    const baseValue = financialDetails.rentalRevenue || 0;
    const amount = (numericValue / 100) * baseValue;

    onFinancialDetailsChange({
      ...financialDetails,
      otherExpenses: numericValue,
      otherExpensesAmount: Number(amount.toFixed(2)),
    });
  };

  const handleRentalRevenueChange = ({ target: { value } }) => {
    const numericValue = Number(value) || 0;
    onFinancialDetailsChange({
      ...financialDetails,
      rentalRevenue: numericValue,
    });
  };

  const handlePropertyTaxAmountChange = ({ target: { value } }) => {
    const numericValue = Number(value) || 0;
    const baseValue = financialDetails.purchasePrice || 0;
    const percentValue =
      baseValue > 0 ? ((numericValue / baseValue) * 100).toFixed(2) : 0;

    onFinancialDetailsChange({
      ...financialDetails,
      propertyTax: Number(percentValue),
      propertyTaxAmount: numericValue,
    });
  };

  const handlePropertyTaxPercentChange = ({ target: { value } }) => {
    const numericValue = Number(value) || 0;
    const baseValue = financialDetails.purchasePrice || 0;
    const amount = (numericValue / 100) * baseValue;

    onFinancialDetailsChange({
      ...financialDetails,
      propertyTax: numericValue,
      propertyTaxAmount: Number(amount.toFixed(2)),
    });
  };

  const handleManagementFeeAmountChange = ({ target: { value } }) => {
    const numericValue = Number(value) || 0;
    const baseValue = financialDetails.rentalRevenue || 0;
    const percentValue =
      baseValue > 0 ? ((numericValue / baseValue) * 100).toFixed(2) : 0;

    onFinancialDetailsChange({
      ...financialDetails,
      managementFee: Number(percentValue),
      managementFeeAmount: numericValue,
    });
  };

  const handleManagementFeePercentChange = ({ target: { value } }) => {
    const numericValue = Number(value) || 0;
    const baseValue = financialDetails.rentalRevenue || 0;
    const amount = (numericValue / 100) * baseValue;
    onFinancialDetailsChange({
      ...financialDetails,
      managementFee: numericValue,
      managementFeeAmount: Number(amount.toFixed(2)),
    });
  };

  const handleDownPaymentPercentChange = ({ target: { value } }) => {
    const numericValue = Number(value) || 0;
    const amount = (numericValue / 100) * financialDetails.purchasePrice;

    onFinancialDetailsChange({
      ...financialDetails,
      downPayment: numericValue,
      downPaymentAmount: amount,
      loanAmount: financialDetails.purchasePrice - amount,
    });
  };

  const handleDownPaymentAmountChange = ({ target: { value } }) => {
    const numericValue = Number(value) || 0;
    const baseValue = financialDetails.purchasePrice;
    const percentValue =
      baseValue > 0 ? ((numericValue / baseValue) * 100).toFixed(2) : 0;

    onFinancialDetailsChange({
      ...financialDetails,
      downPayment: Number(percentValue),
      downPaymentAmount: numericValue,
      loanAmount: baseValue - numericValue,
    });
  };

  // Add calculation functions
  const calculateMonthlyExpenses = () => {
    const {
      channelFeeAmount = 0,
      managementFeeAmount = 0,
      propertyTaxAmount = 0, // This is annual, need to divide by 12
      insurance = 0,
      hoaFees = 0,
      utilities = 0,
      maintenance = 0,
      otherExpensesAmount = 0,
    } = financialDetails;

    return (
      channelFeeAmount +
      managementFeeAmount +
      propertyTaxAmount / 12 + // Convert annual property tax to monthly
      insurance +
      hoaFees +
      utilities +
      maintenance +
      otherExpensesAmount
    );
  };

  const calculateMonthlyLoanPayment = () => {
    if (financialDetails.isCashOnly || !financialDetails.useLoan) {
      return 0;
    }

    const principal = financialDetails.loanAmount || 0;
    const annualRate = (financialDetails.interestRate || 0) / 100;
    const monthlyRate = annualRate / 12;
    const numberOfPayments = (financialDetails.loanTerm || 30) * 12;

    if (monthlyRate === 0 || numberOfPayments === 0) {
      return 0;
    }

    return (
      (principal * monthlyRate * (1 + monthlyRate) ** numberOfPayments) /
      ((1 + monthlyRate) ** numberOfPayments - 1)
    );
  };

  const content = (
    <>
      <StyledAccordion
        expanded={expanded.totalInvestment}
        onChange={handleChange("totalInvestment")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <SummaryRow>
            <Typography>Investment</Typography>
            <Typography>
              $
              {new Intl.NumberFormat().format(
                parseFloat(financialDetails.purchasePrice || 0)
              )}
            </Typography>
          </SummaryRow>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label="Purchase Price"
                tooltip="Property purchase price"
              />
              <StyledTextField
                fullWidth
                size="small"
                placeholder="Amount"
                value={financialDetails.purchasePrice || "0"}
                onChange={handleInputChange("purchasePrice")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label="Closing Costs"
                tooltip="Costs associated with closing the property purchase"
              />
              <StyledTextField
                fullWidth
                size="small"
                placeholder="Percentage"
                value={financialDetails.closingCosts || "0"}
                onChange={handleInputChange("closingCosts")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label="Immediate Repairs"
                tooltip="Estimated cost of immediate repairs needed"
              />
              <StyledTextField
                fullWidth
                size="small"
                placeholder="Amount"
                value={financialDetails.immediateRepairs || "0"}
                onChange={handleInputChange("immediateRepairs")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label="Furnishing Costs"
                tooltip="Estimated cost to furnish the property"
              />
              <StyledTextField
                fullWidth
                size="small"
                placeholder="Amount"
                value={financialDetails.furnishingCosts || "0"}
                onChange={handleInputChange("furnishingCosts")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label="Initial Expenses"
                tooltip="Other initial one-time expenses for property setup"
              />
              <StyledTextField
                fullWidth
                size="small"
                placeholder="Amount"
                value={financialDetails.initialExpenses || "0"}
                onChange={handleInputChange("initialExpenses")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={expanded.revenue}
        onChange={handleChange("revenue")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <SummaryRow>
            <Typography>Revenue</Typography>
            <Typography>
              <NumberFormat
                value={financialDetails.rentalRevenue}
                displayType="text"
                thousandSeparator
                prefix="$"
              />
              /mo
            </Typography>
          </SummaryRow>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label="Monthly Revenue"
                tooltip="Expected monthly revenue from your Airbnb rental"
              />
              <StyledTextField
                fullWidth
                size="small"
                placeholder="Amount"
                value={financialDetails.rentalRevenue}
                onChange={handleRentalRevenueChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label="Annual Revenue"
                tooltip="Calculated annual revenue based on monthly revenue"
              />
              <StyledTextField
                fullWidth
                size="small"
                placeholder="Amount"
                value={financialDetails.rentalRevenue * 12}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={expanded.expenses}
        onChange={handleChange("expenses")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <SummaryRow>
            <Typography>Expenses</Typography>
            <Typography>
              $
              {new Intl.NumberFormat().format(
                Math.round(calculateMonthlyExpenses())
              )}
              /mo
            </Typography>
          </SummaryRow>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label="Channel Fee"
                tooltip="Airbnb's service fee, typically around 3% for hosts"
              />
              <DualInputGroup>
                <Box sx={{ display: "flex", width: "100%" }}>
                  <Box sx={{ flex: 1 }} className="amount-field">
                    <StyledTextField
                      fullWidth
                      size="small"
                      placeholder="Amount"
                      value={financialDetails.channelFeeAmount || "0"}
                      onChange={handleChannelFeeAmountChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }} className="percentage-field">
                    <StyledTextField
                      fullWidth
                      size="small"
                      placeholder="Percentage"
                      value={financialDetails.channelFee || "3"}
                      onChange={handleChannelFeePercentChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              </DualInputGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label="Property Management Fee"
                tooltip="Fee charged by property management company for handling your rental"
              />
              <DualInputGroup>
                <Box sx={{ display: "flex", width: "100%" }}>
                  <Box sx={{ flex: 1 }} className="amount-field">
                    <StyledTextField
                      fullWidth
                      size="small"
                      placeholder="Amount"
                      value={financialDetails.managementFeeAmount || "0"}
                      onChange={handleManagementFeeAmountChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }} className="percentage-field">
                    <StyledTextField
                      fullWidth
                      size="small"
                      placeholder="Percentage"
                      value={financialDetails.managementFee || "0"}
                      onChange={handleManagementFeePercentChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              </DualInputGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label="Property Tax"
                tooltip="Annual property tax amount"
              />
              <DualInputGroup>
                <Box sx={{ display: "flex", width: "100%" }}>
                  <Box sx={{ flex: 1 }} className="amount-field">
                    <StyledTextField
                      fullWidth
                      size="small"
                      placeholder="Amount"
                      value={financialDetails.propertyTaxAmount || 0}
                      onChange={handlePropertyTaxAmountChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }} className="percentage-field">
                    <StyledTextField
                      fullWidth
                      size="small"
                      placeholder="Percentage"
                      value={financialDetails.propertyTax || 0}
                      onChange={handlePropertyTaxPercentChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              </DualInputGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label="Insurance"
                tooltip="Annual property insurance cost"
              />
              <StyledTextField
                fullWidth
                size="small"
                placeholder="Amount"
                value={financialDetails.insurance}
                onChange={handleInputChange("insurance")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label="HOA Fees"
                tooltip="Monthly Homeowner Association fees if applicable"
              />
              <StyledTextField
                fullWidth
                size="small"
                placeholder="Amount"
                value={financialDetails.hoaFees}
                onChange={handleInputChange("hoaFees")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label="Utilities"
                tooltip="Monthly utilities cost including electricity, water, gas, internet"
              />
              <StyledTextField
                fullWidth
                size="small"
                placeholder="Amount"
                value={financialDetails.utilities}
                onChange={handleInputChange("utilities")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label="Maintenance"
                tooltip="Monthly maintenance and repair costs"
              />
              <StyledTextField
                fullWidth
                size="small"
                placeholder="Amount"
                value={financialDetails.maintenance}
                onChange={handleInputChange("maintenance")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label="Other Expenses"
                tooltip="Additional monthly expenses not covered by other categories"
              />
              <DualInputGroup>
                <Box sx={{ display: "flex", width: "100%" }}>
                  <Box sx={{ flex: 1 }} className="amount-field">
                    <StyledTextField
                      fullWidth
                      size="small"
                      placeholder="Amount"
                      value={financialDetails.otherExpensesAmount || "0"}
                      onChange={handleOtherExpensesAmountChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }} className="percentage-field">
                    <StyledTextField
                      fullWidth
                      size="small"
                      placeholder="Percentage"
                      value={financialDetails.otherExpenses || "0"}
                      onChange={handleOtherExpensesPercentChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              </DualInputGroup>
            </Grid>
          </Grid>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion expanded={expanded.loan} onChange={handleChange("loan")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <SummaryRow>
            <Typography>Loan</Typography>
            <Typography>
              {financialDetails.isCashOnly
                ? "$0/mo"
                : `$${new Intl.NumberFormat().format(
                    Math.round(calculateMonthlyLoanPayment())
                  )}/mo`}
            </Typography>
          </SummaryRow>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}
              >
                <Switch
                  checked={financialDetails.isCashOnly}
                  onChange={(e) =>
                    onFinancialDetailsChange({
                      ...financialDetails,
                      isCashOnly: e.target.checked,
                      useLoan: !e.target.checked,
                    })
                  }
                  size="small"
                />
                <Typography>Cash Only</Typography>
              </Box>
            </Grid>
            {!financialDetails.isCashOnly && (
              <>
                <Grid item xs={12} sm={6}>
                  <FieldLabel
                    label="Down Payment"
                    tooltip="Initial payment for the property purchase"
                  />
                  <DualInputGroup>
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <Box sx={{ flex: 1 }} className="amount-field">
                        <StyledTextField
                          fullWidth
                          size="small"
                          placeholder="Amount"
                          value={financialDetails.downPaymentAmount || "0"}
                          onChange={handleDownPaymentAmountChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box sx={{ flex: 1 }} className="percentage-field">
                        <StyledTextField
                          fullWidth
                          size="small"
                          placeholder="Percentage"
                          value={financialDetails.downPayment || "20"}
                          onChange={handleDownPaymentPercentChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  </DualInputGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FieldLabel
                    label="Loan Amount"
                    tooltip="Calculated loan amount based on purchase price and down payment"
                  />
                  <StyledTextField
                    fullWidth
                    size="small"
                    placeholder="Amount"
                    value={financialDetails.loanAmount || "0"}
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FieldLabel
                    label="Interest Rate"
                    tooltip="Annual interest rate for the mortgage"
                  />
                  <StyledTextField
                    fullWidth
                    size="small"
                    placeholder="Percentage"
                    value={financialDetails.interestRate}
                    onChange={handleInputChange("interestRate")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FieldLabel
                    label="Loan Term"
                    tooltip="Length of the mortgage loan in years"
                  />
                  <StyledTextField
                    fullWidth
                    size="small"
                    placeholder="Years"
                    value={financialDetails.loanTerm || 30}
                    onChange={handleInputChange("loanTerm")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">years</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </StyledAccordion>
    </>
  );

  if (disableCard) {
    return content;
  }

  return (
    <Paper
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        backdropFilter: "blur(10px)",
      }}
    >
      {content}
    </Paper>
  );
};

FinancialDetails.propTypes = {
  financialDetails: PropTypes.shape({
    rentalRevenue: PropTypes.number,
    managementFee: PropTypes.number,
    managementFeeAmount: PropTypes.number,
    lodgingTax: PropTypes.number,
    lodgingTaxAmount: PropTypes.number,
    propertyTax: PropTypes.number,
    propertyTaxAmount: PropTypes.number,
    cleaningFees: PropTypes.number,
    maintenance: PropTypes.number,
    insurance: PropTypes.number,
    utilities: PropTypes.number,
    hoaFees: PropTypes.number,
    otherOperating: PropTypes.number,
    purchasePrice: PropTypes.number,
    homeFurnishings: PropTypes.number,
    homeImprovement: PropTypes.number,
    closingCosts: PropTypes.number,
    otherStartup: PropTypes.number,
    immediateRepairs: PropTypes.number,
    furnishingCosts: PropTypes.number,
    totalExpenses: PropTypes.number,
    channelFee: PropTypes.number,
    channelFeeAmount: PropTypes.number,
    downPayment: PropTypes.number,
    downPaymentAmount: PropTypes.number,
    monthlyPayment: PropTypes.number,
    interestRate: PropTypes.number,
    loanAmount: PropTypes.number,
    loanTerm: PropTypes.number,
    useFinancing: PropTypes.bool,
    isCashOnly: PropTypes.bool,
    useLoan: PropTypes.bool,
    otherExpenses: PropTypes.number,
    otherExpensesAmount: PropTypes.number,
    initialExpenses: PropTypes.number,
  }).isRequired,
  onFinancialDetailsChange: PropTypes.func.isRequired,
  disableCard: PropTypes.bool,
};

FinancialDetails.defaultProps = {
  disableCard: false,
};

export default withErrorBoundary(FinancialDetails);
