import React from "react";
import { AuthProvider as OidcAuthProvider } from "react-oidc-context";
import { getOidcConfig } from "../../config/cognito";

const AuthProvider = ({ children }) => {
  const oidcConfig = getOidcConfig();

  return <OidcAuthProvider {...oidcConfig}>{children}</OidcAuthProvider>;
};

export default AuthProvider;
