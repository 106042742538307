import {
  GET_CALCULATOR_ESTIMATE,
  SET_CALCULATOR_DATA_READINESS,
} from "../actions/calculatorActions";

const calculatorReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CALCULATOR_ESTIMATE:
      return {
        ...state,
        ready: true,
        occupancy: action.payload.occupancy,
        averageDailyRate: Math.round(action.payload.average_daily_rate),
        monthlyRevenueDistributions:
          action.payload.monthly_revenue_distributions,
        similarListings: action.payload.similar_listings.map((listing) => ({
          id: listing.id,
          listingId: listing.listing_id,
          listingName: listing.listing_name,
          photoURL: listing.cover_photo_url,
          occupancyRate: listing.occupancy_rate,
          revenue: listing.revenue,
          guests: listing.guests,
          bedrooms: listing.bedrooms,
          baths: listing.baths,
          averageDailyRate: listing.average_daily_rate,
          latitude: listing.latitude,
          longitude: listing.longitude,
        })),
      };

    case SET_CALCULATOR_DATA_READINESS:
      return {
        ...state,
        ready: action.ready,
      };

    default:
      return state;
  }
};

export default calculatorReducer;
