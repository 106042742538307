import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { HelmetProvider } from "react-helmet-async";
import theme from "./theme";
import RoutedApp from "./RoutedApp";
import SEO from "./SEO";

const App = () => {
  // SEO at this level serves as fallback for child pages which do not implement SEO component

  return (
    <HelmetProvider>
      <StyledEngineProvider>
        <ThemeProvider theme={theme}>
          <SEO
            title="Airbnb Data | Short-Term Rental Analytics | Revenue Calculator | Dynamic Pricing"
            description="AirROI empowers Airbnb hosts, property managers, and investors with AI-driven analytics, market research, and dynamic pricing to maximize return on investment."
            type="website"
            url="https://www.airroi.com"
            canonical="https://www.airroi.com"
          />
          <CssBaseline />
          <RoutedApp />
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
};

export default App;
