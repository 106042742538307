const config = {
  userPoolId: "us-west-2_uqxPG9T8t",
  clientId: "jhd7pk12k6927ks67phqa98iq",
  domain: "https://auth.airroi.com", // auth.airroi.com works for both dev and prod
  redirectUri:
    process.env.NODE_ENV === "production"
      ? "https://www.airroi.com/auth/callback"
      : "http://localhost:8080/auth/callback",
  logoutUri:
    process.env.NODE_ENV === "production"
      ? "https://www.airroi.com"
      : "http://localhost:8080",
  responseType: "code",
  scope: "email openid phone profile",
};

const getOidcConfig = () => {
  return {
    authority: `https://cognito-idp.us-west-2.amazonaws.com/${config.userPoolId}`,
    client_id: config.clientId,
    redirect_uri: config.redirectUri,
    response_type: config.responseType,
    scope: config.scope,
  };
};

const getSignOutRedirectUrl = () => {
  // auth.airroi.com works for both dev and prod
  return `https://auth.airroi.com/logout?client_id=${
    config.clientId
  }&logout_uri=${encodeURIComponent(config.logoutUri)}`;
};

export { config, getOidcConfig, getSignOutRedirectUrl };
