/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

const SEO = ({
  title,
  description,
  keywords,
  image,
  url = "https://www.airroi.com",
  type = "website",
  noindex = false,
  canonical,
}) => {
  const siteTitle =
    "AirROI | Airbnb Data | Short-Term Rental Analytics | Revenue Calculator | Dynamic Pricing";
  const fullTitle = title ? `${title} | AirROI` : siteTitle;
  const defaultDescription =
    "AirROI empowers Airbnb hosts, property managers, and investors with AI-driven analytics, market research, and dynamic pricing to maximize return on investment.";
  const defaultImage =
    "https://www.airroi.com/static/airroi-logo-large-compressed.png";

  return (
    <Helmet prioritizeSeoTags>
      {/* Basic meta tags */}
      <title>{fullTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      {keywords && (
        <meta
          name="keywords"
          content={Array.isArray(keywords) ? keywords.join(", ") : keywords}
        />
      )}
      {noindex && <meta name="robots" content="noindex,nofollow" />}
      {canonical && <link rel="canonical" href={canonical} />}

      {/* Open Graph meta tags */}
      <meta property="og:title" content={fullTitle} />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta property="og:type" content={type} />
      <meta property="og:image" content={image || defaultImage} />
      {url && <meta property="og:url" content={url} />}

      {/* Twitter Card meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta
        name="twitter:description"
        content={description || defaultDescription}
      />
      <meta name="twitter:image" content={image || defaultImage} />

      {/* Schema.org JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": type === "article" ? "Article" : "WebSite",
          name: fullTitle,
          description: description || defaultDescription,
          image: image || defaultImage,
          url: url || "https://www.airroi.com",
          publisher: {
            "@type": "Organization",
            name: "AirROI",
            logo: {
              "@type": "ImageObject",
              url: "https://www.airroi.com/static/airroi-logo-small.png",
            },
          },
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
