import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, TextField, Autocomplete, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import NumberFormat from "react-number-format";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const PropertyDetails = ({
  propertyDetails,
  onInputChange,
  predictions,
  searchInput,
  handlePlaceSelect,
  handleAddressSearch,
  isLoaded,
}) => {
  // Check for empty required fields and > 0 for numeric fields
  const isEmpty = (value) =>
    value === null || value === undefined || value === "";
  const hasError = {
    address: isEmpty(propertyDetails.address),
    purchasePrice:
      isEmpty(propertyDetails.purchasePrice) ||
      propertyDetails.purchasePrice <= 0,
    bedrooms:
      isEmpty(propertyDetails.bedrooms) || propertyDetails.bedrooms <= 0,
    bathrooms:
      isEmpty(propertyDetails.bathrooms) || propertyDetails.bathrooms <= 0,
    guests: isEmpty(propertyDetails.guests) || propertyDetails.guests <= 0,
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={8}>
          <StyledAutocomplete
            size="small"
            freeSolo
            options={predictions}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.description
            }
            filterOptions={(x) => x}
            value={propertyDetails.address}
            onChange={(event, newValue) => {
              if (newValue && typeof newValue !== "string") {
                handlePlaceSelect(newValue.place_id);
              }
            }}
            onInputChange={(event, newInputValue) => {
              handleAddressSearch(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Address"
                variant="outlined"
                fullWidth
                placeholder="Enter property address"
                error={hasError.address}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Typography variant="body2">{option.description}</Typography>
              </li>
            )}
            loading={!isLoaded}
            loadingText="Loading Google Places..."
            noOptionsText="No addresses found"
          />
        </Grid>
        <Grid item xs={4}>
          <NumberFormat
            customInput={StyledTextField}
            thousandSeparator
            prefix="$"
            size="small"
            fullWidth
            label="Purchase Price"
            value={propertyDetails.purchasePrice ?? ""}
            onValueChange={(values) => {
              onInputChange("purchasePrice")({
                target: { value: values.value },
              });
            }}
            error={hasError.purchasePrice}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <StyledTextField
            size="small"
            fullWidth
            label="Bedrooms"
            type="number"
            value={propertyDetails.bedrooms ?? ""}
            onChange={onInputChange("bedrooms")}
            InputProps={{
              inputProps: { min: 0 },
            }}
            error={hasError.bedrooms}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTextField
            size="small"
            fullWidth
            label="Bathrooms"
            type="number"
            value={propertyDetails.bathrooms ?? ""}
            onChange={onInputChange("bathrooms")}
            InputProps={{
              inputProps: { min: 0 },
            }}
            error={hasError.bathrooms}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTextField
            size="small"
            fullWidth
            label="Guests"
            type="number"
            value={propertyDetails.guests ?? ""}
            onChange={onInputChange("guests")}
            InputProps={{
              inputProps: { min: 0 },
            }}
            error={hasError.guests}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

PropertyDetails.propTypes = {
  propertyDetails: PropTypes.shape({
    address: PropTypes.string,
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    guests: PropTypes.number,
    purchasePrice: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  onInputChange: PropTypes.func.isRequired,
  predictions: PropTypes.array.isRequired,
  searchInput: PropTypes.string.isRequired,
  handlePlaceSelect: PropTypes.func.isRequired,
  handleAddressSearch: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool,
};

PropertyDetails.defaultProps = {
  isLoaded: true,
};

export default PropertyDetails;
