import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";

import {
  Box,
  Button,
  Collapse,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { APP_BAR_HEIGHT } from "./cssvars";
import { resetAirbnbListings } from "../actions/airbnbListingsActions";
import AuthButtons from "./auth/AuthButtons";

const styles = {
  menuText: {
    color: "black",
    fontWeight: 400,
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    padding: "8px 12px",
    textTransform: "none",
  },
};

const AppHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 440,
  });
  const location = useLocation();
  const dispatch = useDispatch();
  const [accountEl, setAccountEl] = useState(null);
  const accountMenuOpen = Boolean(accountEl);
  const [mobileMenuEl, setMobileMenuEl] = useState(null);
  const mobileMenuOpen = Boolean(mobileMenuEl);

  // State for submenu open status (common for both mobile and desktop)
  const [submenuOpen, setSubmenuOpen] = useState({});
  // State for submenu anchor elements (desktop)
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState({});

  const { isAuthenticated: authenticated } = useAuth();

  const handleClickAccountMenu = (event) => {
    setAccountEl(event.currentTarget);
  };
  const handleClickMobileMenu = (event) => {
    setMobileMenuEl(event.currentTarget);
  };
  const handleCloseAccountMenu = () => {
    setAccountEl(null);
  };
  const handleCloseMobileMenu = () => {
    setMobileMenuEl(null);
    setSubmenuOpen({});
  };

  const getAppBarBackgroundColor = (isTriggered, currentLocation) => {
    if (currentLocation.pathname === "/") {
      return isTriggered ? "white" : "transparent";
    }
    return "#fafafa";
  };

  const getAppBarPosition = (isTriggered, currentLocation) => {
    if (currentLocation.pathname === "/") {
      return isTriggered ? "sticky" : "static";
    }
    return "sticky";
  };

  // Updated menu items with "Support" submenu
  const menuItems = [
    {
      label: "Products",
      submenu: [
        { label: "Market Atlas", path: "/atlas/map" },
        { label: "Analytical Insights", path: "/atlas/charts/trend" },
        { label: "Revenue Calculator", path: "/calculator" },
        {
          label: "Dynamic Pricing",
          path: authenticated
            ? "/atlas/dynamic-price/listings"
            : "/atlas/dynamic-price/listings/demo",
        },
        { label: "Regional Report", path: "/report", external: true },
        { label: "Data Portal", path: "/data-portal", external: true },
      ],
    },
    {
      label: "Support",
      submenu: [
        { label: "FAQ", path: "/faq" },
        { label: "Contact Us", path: "/contact" },
        { label: "About Us", path: "/about" },
      ],
    },
    { label: "Blog", path: "/blog" },
  ];

  return (
    <Box
      component="header"
      sx={{
        position: getAppBarPosition(trigger, location),
        top: 0,
        bgcolor: getAppBarBackgroundColor(trigger, location),
        zIndex: (t) => t.zIndex.drawer,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height={APP_BAR_HEIGHT}
      >
        <Link
          underline="none"
          to="/"
          component={RouterLink}
          onClick={() => dispatch(resetAirbnbListings())}
          aria-label="Waterfront Home"
        >
          <Box
            sx={{
              position: "relative",
              left: { xs: 16, sm: 32 },
              width: "80px",
              height: "26px",
            }}
          >
            <svg
              width={214.5 * 0.5}
              height={51.00329538987627 * 0.5}
              viewBox="0 0 370 87.97771232752551"
              aria-hidden="true"
            >
              <defs>
                <linearGradient
                  id="text-gradient"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="0%"
                >
                  <stop offset="0%" stopColor="rgb(51, 153, 255)" />
                  <stop offset="100%" stopColor="rgb(0, 115, 230)" />
                </linearGradient>
              </defs>
              <g id="logo-icon" fill="#1976d2">
                <path d="M63.9 39H7.7C6.2 39 5 37.8 5 36.3s1.2-2.7 2.7-2.7h56.2c2.8 0 5.1-2.3 5.1-5.1s-2.3-5.1-5.1-5.1c-2.8 0-5.1 2.3-5.1 5.1 0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7c0-5.8 4.7-10.5 10.5-10.5 5.8 0 10.5 4.7 10.5 10.5S69.7 39 63.9 39" />
                <path d="M84.5 56.6H28.3c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7h56.2c2.8 0 5.1-2.3 5.1-5.1 0-2.8-2.3-5.1-5.1-5.1s-5.1 2.3-5.1 5.1c0 1.5-1.2 2.7-2.7 2.7S74 47.6 74 46.1c0-5.8 4.7-10.5 10.5-10.5S95 40.3 95 46.1C95 52 90.3 56.6 84.5 56.6" />
                <path d="M68.9 82H12.7c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7h56.2c2.8 0 5.1-2.3 5.1-5.1s-2.3-5.1-5.1-5.1c-2.8 0-5.1 2.3-5.1 5.1 0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7c0-5.8 4.7-10.5 10.5-10.5 5.8 0 10.5 4.7 10.5 10.5S74.7 82 68.9 82" />
              </g>
              <g
                id="logo-text"
                transform="matrix(2.9463760105576076,0,0,2.9463760105576076,114.93223247507363,-33.82793183879921)"
                fill="url(#text-gradient)"
              >
                <path d="M11.8 40.52 c-5.76 0 -10.08 -4.52 -10.08 -10.52 s4.32 -10.44 10.08 -10.44 c3.56 0 6.28 1.6 7.88 4.08 l0 -3.6 l3.08 0 l0 19.96 l-3.08 0 l0 -3.64 c-1.6 2.56 -4.32 4.16 -7.88 4.16 z M12.24 37.72 c4.12 0 7.52 -3.28 7.52 -7.68 s-3.4 -7.68 -7.52 -7.68 c-4.4 0 -7.48 3.24 -7.48 7.68 c0 4.4 3.08 7.68 7.48 7.68 z M28.794 16.32 c-1.2 0 -2.12 -0.92 -2.12 -2.12 c0 -1.12 0.92 -2.04 2.12 -2.04 s2.12 0.92 2.12 2.04 c0 1.2 -0.92 2.12 -2.12 2.12 z M27.274 40 l0 -19.96 l3.08 0 l0 19.96 l-3.08 0 z M37.948 23.48 c1.2 -2.6 3.64 -4.16 7.16 -3.68 l0 2.88 c-4.32 -0.56 -7.16 1.72 -7.16 8 l0 9.32 l-3.08 0 l0 -19.96 l3.08 0 l0 3.44 z M50.462 23.48 c1.2 -2.6 3.64 -4.16 7.16 -3.68 l0 2.88 c-4.32 -0.56 -7.16 1.72 -7.16 8 l0 9.32 l-3.08 0 l0 -19.96 l3.08 0 l0 3.44 z M69.176 40.52 c-6.04 0 -10.56 -4.56 -10.56 -10.52 s4.52 -10.44 10.56 -10.44 s10.52 4.48 10.52 10.44 s-4.48 10.52 -10.52 10.52 z M69.176 37.68 c4.28 0 7.48 -3.28 7.48 -7.64 s-3.2 -7.68 -7.48 -7.68 c-4.32 0 -7.56 3.32 -7.56 7.68 s3.24 7.64 7.56 7.64 z M84.44999999999999 16.32 c-1.2 0 -2.12 -0.92 -2.12 -2.12 c0 -1.12 0.92 -2.04 2.12 -2.04 s2.12 0.92 2.12 2.04 c0 1.2 -0.92 2.12 -2.12 2.12 z M82.92999999999999 40 l0 -19.96 l3.08 0 l0 19.96 l-3.08 0 z" />
              </g>
            </svg>
          </Box>
        </Link>

        <Box display="flex" alignItems="center" mr={{ xs: 1, sm: 3 }}>
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClickMobileMenu}
                aria-label="Open main menu"
                aria-expanded={mobileMenuOpen}
                aria-controls="mobile-menu"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="mobile-menu"
                anchorEl={mobileMenuEl}
                open={mobileMenuOpen}
                onClose={handleCloseMobileMenu}
                aria-label="Mobile navigation menu"
              >
                <nav aria-label="Main navigation">
                  {menuItems.map((item) => (
                    <React.Fragment key={item.label}>
                      <MenuItem
                        component={RouterLink}
                        onClick={() =>
                          item.submenu
                            ? setSubmenuOpen((prev) => ({
                                ...prev,
                                [item.label]: !prev[item.label],
                              }))
                            : handleCloseMobileMenu()
                        }
                        to={item.submenu ? "#" : item.path}
                        aria-expanded={
                          item.submenu ? !!submenuOpen[item.label] : undefined
                        }
                        aria-controls={
                          item.submenu ? `${item.label}-submenu` : undefined
                        }
                      >
                        {item.label}
                        {item.submenu &&
                          (submenuOpen[item.label] ? (
                            <RemoveIcon sx={{ fontSize: 14, ml: "4px" }} />
                          ) : (
                            <AddIcon sx={{ fontSize: 14, ml: "4px" }} />
                          ))}
                      </MenuItem>
                      {item.submenu && (
                        <Collapse
                          in={submenuOpen[item.label]}
                          timeout="auto"
                          id={`${item.label}-submenu`}
                        >
                          <nav aria-label={`${item.label} submenu`}>
                            {item.submenu.map((subitem) => (
                              <MenuItem
                                key={subitem.label}
                                component={subitem.external ? "a" : RouterLink}
                                href={
                                  subitem.external ? subitem.path : undefined
                                }
                                to={subitem.external ? undefined : subitem.path}
                                onClick={() => {
                                  handleCloseMobileMenu();
                                  setSubmenuOpen((prev) => ({
                                    ...prev,
                                    [item.label]: false,
                                  }));
                                }}
                                sx={{ pl: 4 }}
                              >
                                {subitem.label}
                              </MenuItem>
                            ))}
                          </nav>
                        </Collapse>
                      )}
                    </React.Fragment>
                  ))}
                </nav>
                <AuthButtons />
              </Menu>
            </>
          ) : (
            <nav aria-label="Main navigation">
              {menuItems.map((item) => (
                <React.Fragment key={item.label}>
                  {(() => {
                    if (item.submenu) {
                      return (
                        <>
                          <Button
                            onClick={(event) => {
                              setSubmenuAnchorEl((prev) => ({
                                ...prev,
                                [item.label]: event.currentTarget,
                              }));
                              setSubmenuOpen((prev) => ({
                                ...prev,
                                [item.label]: true,
                              }));
                            }}
                            endIcon={
                              submenuOpen[item.label] ? (
                                <ExpandLessIcon sx={{ marginLeft: "-4px" }} />
                              ) : (
                                <ExpandMoreIcon sx={{ marginLeft: "-4px" }} />
                              )
                            }
                            sx={{ ...styles.menuText, fontSize: "16px" }}
                            aria-expanded={submenuOpen[item.label]}
                            aria-controls={`${item.label}-desktop-submenu`}
                          >
                            {item.label}
                          </Button>
                          <Menu
                            id={`${item.label}-desktop-submenu`}
                            anchorEl={submenuAnchorEl[item.label]}
                            open={submenuOpen[item.label]}
                            onClose={() => {
                              setSubmenuAnchorEl((prev) => ({
                                ...prev,
                                [item.label]: null,
                              }));
                              setSubmenuOpen((prev) => ({
                                ...prev,
                                [item.label]: false,
                              }));
                            }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <nav aria-label={`${item.label} submenu`}>
                              {item.submenu.map((subitem) => (
                                <MenuItem
                                  key={subitem.label}
                                  component={
                                    subitem.external ? "a" : RouterLink
                                  }
                                  href={
                                    subitem.external ? subitem.path : undefined
                                  }
                                  to={
                                    !subitem.external ? subitem.path : undefined
                                  }
                                  onClick={() => {
                                    setSubmenuAnchorEl((prev) => ({
                                      ...prev,
                                      [item.label]: null,
                                    }));
                                    setSubmenuOpen((prev) => ({
                                      ...prev,
                                      [item.label]: false,
                                    }));
                                  }}
                                >
                                  {subitem.label}
                                </MenuItem>
                              ))}
                            </nav>
                          </Menu>
                        </>
                      );
                    }

                    if (item.external) {
                      return (
                        <Link
                          underline="none"
                          href={item.path}
                          sx={styles.menuText}
                        >
                          {item.label}
                        </Link>
                      );
                    }

                    return (
                      <Link
                        underline="none"
                        to={item.path}
                        component={RouterLink}
                        sx={styles.menuText}
                      >
                        {item.label}
                      </Link>
                    );
                  })()}
                </React.Fragment>
              ))}
            </nav>
          )}
          <IconButton
            onClick={handleClickAccountMenu}
            aria-label="User account menu"
            aria-expanded={accountMenuOpen}
            aria-controls="account-menu"
          >
            <AccountCircleIcon color={authenticated ? "primary" : "inherit"} />
          </IconButton>
          <Menu
            id="account-menu"
            anchorEl={accountEl}
            open={accountMenuOpen}
            onClose={handleCloseAccountMenu}
            aria-label="Account menu"
          >
            <AuthButtons />
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default AppHeader;
