import React from "react";
import { Box, Container, Typography, Link, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";

const SocialLink = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.text.primary,
  textDecoration: "none",
  marginRight: theme.spacing(3),
  marginBottom: theme.spacing(2),
  transition: "color 0.2s ease-in-out",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const SocialIcon = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),
  display: "flex",
  alignItems: "center",
}));

const MaintenancePage = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="md" sx={{ py: 8, textAlign: "center" }}>
        {/* Logo */}
        <Box sx={{ mb: 4 }}>
          <svg width="180" height="42" viewBox="0 0 370 87.97771232752551">
            <defs>
              <linearGradient
                id="text-gradient"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
              >
                <stop offset="0%" stopColor="rgb(51, 153, 255)" />
                <stop offset="100%" stopColor="rgb(0, 115, 230)" />
              </linearGradient>
            </defs>
            <g id="logo-icon" fill="#1976d2">
              <path d="M63.9 39H7.7C6.2 39 5 37.8 5 36.3s1.2-2.7 2.7-2.7h56.2c2.8 0 5.1-2.3 5.1-5.1s-2.3-5.1-5.1-5.1c-2.8 0-5.1 2.3-5.1 5.1 0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7c0-5.8 4.7-10.5 10.5-10.5 5.8 0 10.5 4.7 10.5 10.5S69.7 39 63.9 39" />
              <path d="M84.5 56.6H28.3c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7h56.2c2.8 0 5.1-2.3 5.1-5.1 0-2.8-2.3-5.1-5.1-5.1s-5.1 2.3-5.1 5.1c0 1.5-1.2 2.7-2.7 2.7S74 47.6 74 46.1c0-5.8 4.7-10.5 10.5-10.5S95 40.3 95 46.1C95 52 90.3 56.6 84.5 56.6" />
              <path d="M68.9 82H12.7c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7h56.2c2.8 0 5.1-2.3 5.1-5.1s-2.3-5.1-5.1-5.1c-2.8 0-5.1 2.3-5.1 5.1 0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7c0-5.8 4.7-10.5 10.5-10.5 5.8 0 10.5 4.7 10.5 10.5S74.7 82 68.9 82" />
            </g>
            <g
              id="logo-text"
              transform="matrix(2.9463760105576076,0,0,2.9463760105576076,114.93223247507363,-33.82793183879921)"
              fill="url(#text-gradient)"
            >
              <path d="M11.8 40.52 c-5.76 0 -10.08 -4.52 -10.08 -10.52 s4.32 -10.44 10.08 -10.44 c3.56 0 6.28 1.6 7.88 4.08 l0 -3.6 l3.08 0 l0 19.96 l-3.08 0 l0 -3.64 c-1.6 2.56 -4.32 4.16 -7.88 4.16 z M12.24 37.72 c4.12 0 7.52 -3.28 7.52 -7.68 s-3.4 -7.68 -7.52 -7.68 c-4.4 0 -7.48 3.24 -7.48 7.68 c0 4.4 3.08 7.68 7.48 7.68 z M28.794 16.32 c-1.2 0 -2.12 -0.92 -2.12 -2.12 c0 -1.12 0.92 -2.04 2.12 -2.04 s2.12 0.92 2.12 2.04 c0 1.2 -0.92 2.12 -2.12 2.12 z M27.274 40 l0 -19.96 l3.08 0 l0 19.96 l-3.08 0 z M37.948 23.48 c1.2 -2.6 3.64 -4.16 7.16 -3.68 l0 2.88 c-4.32 -0.56 -7.16 1.72 -7.16 8 l0 9.32 l-3.08 0 l0 -19.96 l3.08 0 l0 3.44 z M50.462 23.48 c1.2 -2.6 3.64 -4.16 7.16 -3.68 l0 2.88 c-4.32 -0.56 -7.16 1.72 -7.16 8 l0 9.32 l-3.08 0 l0 -19.96 l3.08 0 l0 3.44 z M69.176 40.52 c-6.04 0 -10.56 -4.56 -10.56 -10.52 s4.52 -10.44 10.56 -10.44 s10.52 4.48 10.52 10.44 s-4.48 10.52 -10.52 10.52 z M69.176 37.68 c4.28 0 7.48 -3.28 7.48 -7.64 s-3.2 -7.68 -7.48 -7.68 c-4.32 0 -7.56 3.32 -7.56 7.68 s3.24 7.64 7.56 7.64 z M84.44999999999999 16.32 c-1.2 0 -2.12 -0.92 -2.12 -2.12 c0 -1.12 0.92 -2.04 2.12 -2.04 s2.12 0.92 2.12 2.04 c0 1.2 -0.92 2.12 -2.12 2.12 z M82.92999999999999 40 l0 -19.96 l3.08 0 l0 19.96 l-3.08 0 z" />
            </g>
          </svg>
        </Box>

        {/* Main message */}
        <Typography
          variant="h6"
          color="text.secondary"
          sx={{ mb: 4, maxWidth: 600, mx: "auto" }}
        >
          We're making our website even better for you! Scheduled maintenance is
          in progress, we'll be back shortly.
        </Typography>

        {/* Divider */}
        <Box
          sx={{
            width: "100%",
            height: 2,
            background:
              "linear-gradient(90deg, transparent, rgba(0,0,0,0.1), transparent)",
            mb: 4,
          }}
        />

        {/* Social links section */}
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mb: 3 }}>
          Follow Us
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            mb: 4,
          }}
        >
          <SocialLink
            href="https://www.linkedin.com/company/airroi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcon>
              <LinkedInIcon />
            </SocialIcon>
            LinkedIn
          </SocialLink>

          <SocialLink
            href="https://www.twitter.com/airroi_"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcon>
              <TwitterIcon />
            </SocialIcon>
            Twitter
          </SocialLink>

          <SocialLink
            href="https://www.youtube.com/@airroi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcon>
              <YouTubeIcon />
            </SocialIcon>
            YouTube
          </SocialLink>

          <SocialLink
            href="https://www.facebook.com/airroi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcon>
              <FacebookIcon />
            </SocialIcon>
            Facebook
          </SocialLink>
        </Box>

        <Typography variant="body2" color="text.secondary">
          Thank you for your patience as we work to improve our service.
        </Typography>
      </Container>
    </Box>
  );
};

export default MaintenancePage;
