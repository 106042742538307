import React, { useState, useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Typography, Paper, Grid, Button } from "@mui/material";
import NumberFormat from "react-number-format";
import debounce from "lodash.debounce";
import { fetchCalculatorPrediction } from "../../actions/calculatorActions";
import useAddressAutocomplete from "../../hooks/useAddressAutocomplete";

// Internal components
import PropertyVariables from "./PropertyVariables";
import PropertyComparables from "./PropertyComparables";
import InvestmentProjection from "./InvestmentProjection";
import FinancialDetails from "./FinancialDetails";
import HeroSection from "./HeroSection";
import PropertyOverview from "./PropertyOverview";
import MetricCard from "./MetricCard";

const AirbnbCalculator = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const calculatorState = useSelector((state) => state.calculator);

  const { predictions, handleAddressSearch, handlePlaceSelect, loadError } =
    useAddressAutocomplete();

  const [searchInput, setSearchInput] = useState("");
  const isFirstLoadRef = useRef(true);

  const [propertyDetails, setPropertyDetails] = useState({
    address: "",
    bedrooms: null,
    bathrooms: null,
    guests: null,
    purchasePrice: null,
    lat: null,
    lng: null,
  });

  const [calculatedMetrics, setCalculatedMetrics] = useState({
    score: null,
    annualProfit: null,
    capRate: null,
    cashOnCash: null,
    estimatedRevenue: null,
    occupancyRate: null,
    averageDailyRate: null,
    monthlyMortgage: null,
    cashFlow: null,
    roi: null,
  });

  // Add new state for shared components
  const [sliderOccupancyRate, setSliderOccupancyRate] = useState(
    calculatorState.occupancy ? Math.round(calculatorState.occupancy * 100) : 0
  );
  const [sliderAvgDailyRate, setSliderAvgDailyRate] = useState(
    calculatorState.averageDailyRate
      ? Math.round(calculatorState.averageDailyRate)
      : 0
  );
  const [occupancyPercentile, setOccupancyPercentile] = useState(null);
  const [dailyRatePercentile, setDailyRatePercentile] = useState(null);
  // Track whether values have been manually adjusted (don't use for UI, just for state management)
  const [hasUserAdjustedValues, setHasUserAdjustedValues] = useState(false);

  // Add debounced fetch prediction using lodash
  const debouncedFetchPrediction = useCallback(
    debounce((details) => {
      // Reset user adjustment state to allow new predictions to update sliders
      setHasUserAdjustedValues(false);
      dispatch(fetchCalculatorPrediction(details));
    }, 1000),
    [dispatch]
  );

  // Add immediate fetch function for first load
  const fetchPredictionImmediately = useCallback(
    (details) => {
      // Reset user adjustment state to allow new predictions to update sliders
      setHasUserAdjustedValues(false);
      dispatch(fetchCalculatorPrediction(details));
    },
    [dispatch]
  );

  const [financialDetails, setFinancialDetails] = useState({
    purchasePrice: 0,
    downPayment: 20,
    downPaymentAmount: 0,
    interestRate: 6.5,
    loanAmount: 0,
    loanTerm: 30,
    rentalRevenue: 0,
    managementFee: 20,
    managementFeeAmount: 0,
    channelFee: 3,
    channelFeeAmount: 0,
    propertyTax: 1,
    propertyTaxAmount: 0,
    cleaningFees: 0,
    maintenance: 0,
    insurance: 0,
    utilities: 0,
    hoaFees: 0,
    otherExpenses: 0,
    otherExpensesAmount: 0,
    closingCosts: 0,
    immediateRepairs: 0,
    furnishingCosts: 0,
    initialExpenses: 0,
    isCashOnly: false,
    useLoan: true,
  });

  // Calculate seasonality data from monthly revenue distributions
  const seasonalityData = React.useMemo(() => {
    if (!calculatorState.monthlyRevenueDistributions) {
      return [];
    }

    // Use calculated metrics for annual revenue if available, otherwise calculate from sliders
    const annualRevenue =
      calculatedMetrics.annualRevenue ||
      365 * sliderAvgDailyRate * (sliderOccupancyRate / 100);

    return calculatorState.monthlyRevenueDistributions.map(
      (distribution, index) => {
        const monthlyRevenue = annualRevenue * distribution;
        // Assuming profit is roughly 65% of revenue for this example
        const profit = monthlyRevenue * 0.65;

        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        return {
          month: months[index],
          revenue: Math.round(monthlyRevenue),
          profit: Math.round(profit),
        };
      }
    );
  }, [
    calculatorState.monthlyRevenueDistributions,
    sliderOccupancyRate,
    sliderAvgDailyRate,
    calculatedMetrics.annualRevenue,
  ]);

  // Centralized revenue calculation function
  const calculateRevenue = useCallback(
    (occupancy, dailyRate, overrideMonthly = null) => {
      if (overrideMonthly !== null) {
        const annualRevenue = overrideMonthly * 12;
        return {
          annualRevenue,
          monthlyRevenue: overrideMonthly,
          dailyRevenue: overrideMonthly / 30.44, // average days per month
        };
      }

      // Convert occupancy from percentage to fraction for calculation
      const occupancyFraction = occupancy / 100;
      const annualRevenue = Math.round(365 * dailyRate * occupancyFraction);
      return {
        annualRevenue,
        monthlyRevenue: Math.round(annualRevenue / 12),
        dailyRevenue: dailyRate,
      };
    },
    []
  );

  // Update calculated metrics when occupancy or daily rate changes
  const updateMetrics = useCallback(
    (newOccupancy = null, newDailyRate = null, overrideMonthly = null) => {
      const occupancy = newOccupancy ?? sliderOccupancyRate;
      const dailyRate = newDailyRate ?? sliderAvgDailyRate;

      const revenue = calculateRevenue(occupancy, dailyRate, overrideMonthly);

      setCalculatedMetrics((prev) => ({
        ...prev,
        occupancyRate: occupancy,
        averageDailyRate: dailyRate,
        estimatedRevenue: revenue.monthlyRevenue,
        annualRevenue: revenue.annualRevenue,
      }));

      // Update financial details if they exist
      if (financialDetails) {
        setFinancialDetails((prev) => ({
          ...prev,
          rentalRevenue: Number(revenue.monthlyRevenue),
        }));
      }
    },
    [
      sliderOccupancyRate,
      sliderAvgDailyRate,
      financialDetails,
      calculateRevenue,
    ]
  );

  // Handler for occupancy rate changes
  const handleOccupancyChange = useCallback(
    (event, newValue) => {
      // Determine if this is from user interaction vs system update
      // Check if event is null but newValue is from prediction (meaning system-initiated change)
      const isSystemChange =
        event === null &&
        calculatorState.occupancy &&
        Math.abs(newValue - calculatorState.occupancy * 100) < 0.1;

      // If user manually changed it, mark it
      if (!isSystemChange) {
        setHasUserAdjustedValues(true);
      }

      setSliderOccupancyRate(newValue);
      updateMetrics(newValue, null);
      // Update percentile as in original code
      setOccupancyPercentile(Math.round(100 - (newValue / 100) * 100));
    },
    [updateMetrics, calculatorState.occupancy]
  );

  // Handler for daily rate changes
  const handleDailyRateChange = useCallback(
    (event, newValue) => {
      // Determine if this is from user interaction vs system update
      // Check if event is null but newValue is from prediction (meaning system-initiated change)
      const isSystemChange =
        event === null &&
        calculatorState.averageDailyRate &&
        Math.abs(newValue - calculatorState.averageDailyRate) < 0.1;

      // If user manually changed it, mark it
      if (!isSystemChange) {
        setHasUserAdjustedValues(true);
      }

      setSliderAvgDailyRate(newValue);
      updateMetrics(null, newValue);
      // Update percentile as in original code
      setDailyRatePercentile(Math.round(100 - (newValue / 1000) * 100));
    },
    [updateMetrics, calculatorState.averageDailyRate]
  );

  // Handle financial details changes
  const handleFinancialDetailsChange = (newDetails, options = {}) => {
    setFinancialDetails(newDetails);

    // Sync purchase price with PropertyDetails if requested
    if (
      options.syncPropertyDetails &&
      newDetails.purchasePrice !== propertyDetails.purchasePrice
    ) {
      setPropertyDetails((prev) => ({
        ...prev,
        purchasePrice: newDetails.purchasePrice,
      }));
    }
  };

  const calculateMortgage = (
    principal,
    downPaymentPercent,
    interestRate,
    years = 30
  ) => {
    const downPayment = (principal * downPaymentPercent) / 100;
    const loanAmount = principal - downPayment;
    const monthlyRate = interestRate / 100 / 12;
    const numberOfPayments = years * 12;

    const monthlyPayment =
      (loanAmount * (monthlyRate * (1 + monthlyRate) ** numberOfPayments)) /
      ((1 + monthlyRate) ** numberOfPayments - 1);

    return monthlyPayment;
  };

  const estimateRevenue = (bedrooms, bathrooms, propertyLocation) => {
    const { lat, lng } = propertyDetails;
    const baseRevenue = 5000; // Base monthly revenue
    const bedroomMultiplier = bedrooms * 1.2;
    const bathroomMultiplier = bathrooms * 1.1;

    const getLocationMultiplier = () => {
      const addressStr = propertyLocation.address?.toLowerCase() || "";
      if (
        addressStr.includes("new york") ||
        addressStr.includes("san francisco")
      ) {
        return 2.0;
      }
      if (addressStr.includes("los angeles") || addressStr.includes("miami")) {
        return 1.8;
      }
      if (addressStr.includes("chicago") || addressStr.includes("boston")) {
        return 1.6;
      }
      return 1.4; // Default multiplier for other locations
    };

    return Math.round(
      baseRevenue *
        bedroomMultiplier *
        bathroomMultiplier *
        getLocationMultiplier()
    );
  };

  const calculateOperatingCosts = (purchasePrice) => {
    const annualPropertyTax = purchasePrice * 0.01;
    const annualInsurance = purchasePrice * 0.005;
    const annualMaintenance = purchasePrice * 0.01;
    const monthlyUtilities = 200;
    const monthlyCleaningPerBooking = 100;
    const monthlyManagement = purchasePrice * 0.001;

    return {
      propertyTax: annualPropertyTax / 12,
      insurance: annualInsurance / 12,
      maintenance: annualMaintenance / 12,
      utilities: monthlyUtilities,
      cleaning: monthlyCleaningPerBooking * 15,
      management: monthlyManagement,
    };
  };

  const handleCalculate = () => {
    const { purchasePrice, bedrooms, bathrooms, address, lat, lng } =
      propertyDetails;

    const { downPayment, interestRate } = financialDetails;
    console.log("purchasePrice", purchasePrice);

    if (
      !purchasePrice ||
      !downPayment ||
      !interestRate ||
      !bedrooms ||
      !bathrooms
    ) {
      return;
    }

    const monthlyMortgage = calculateMortgage(
      parseFloat(purchasePrice),
      parseFloat(downPayment),
      parseFloat(interestRate)
    );

    const revenueEstimates = estimateRevenue(
      parseFloat(bedrooms),
      parseFloat(bathrooms),
      { address, lat, lng }
    );

    const operatingCosts = calculateOperatingCosts(parseFloat(purchasePrice));

    const totalMonthlyExpenses =
      monthlyMortgage +
      operatingCosts.propertyTax +
      operatingCosts.insurance +
      operatingCosts.maintenance +
      operatingCosts.utilities +
      operatingCosts.cleaning +
      operatingCosts.management;

    const monthlyCashFlow =
      revenueEstimates.monthlyRevenue - totalMonthlyExpenses;

    const downPaymentAmount =
      (parseFloat(purchasePrice) * parseFloat(downPayment)) / 100;
    const annualCashFlow = monthlyCashFlow * 12;
    const roi = (annualCashFlow / downPaymentAmount) * 100;

    setCalculatedMetrics({
      estimatedRevenue: revenueEstimates.monthlyRevenue,
      occupancyRate: revenueEstimates.occupancyRate,
      averageDailyRate: revenueEstimates.dailyRate,
      monthlyMortgage: Math.round(monthlyMortgage),
      cashFlow: Math.round(monthlyCashFlow),
      roi: Math.round(roi * 10) / 10,
    });
  };

  const handlePropertyDetailsCalculate = (options = {}) => {
    // Sync purchase price with FinancialDetails if requested
    if (
      options.syncFinancialDetails &&
      propertyDetails.purchasePrice !== financialDetails.purchasePrice
    ) {
      setFinancialDetails((prev) => ({
        ...prev,
        purchasePrice: propertyDetails.purchasePrice,
      }));
    }
    handleCalculate();
  };

  const onPlaceSelect = useCallback(
    async (placeId) => {
      const result = await handlePlaceSelect(placeId);
      if (result) {
        setPropertyDetails((prev) => ({
          ...prev,
          address: result.formattedAddress,
          lat: result.location.lat(),
          lng: result.location.lng(),
        }));
      }
    },
    [handlePlaceSelect]
  );

  const handleInputChange =
    (field) =>
    ({ target: { value } }) => {
      const numericFields = [
        "bedrooms",
        "bathrooms",
        "guests",
        "purchasePrice",
      ];
      const newValue = numericFields.includes(field) ? Number(value) : value;
      setPropertyDetails({
        ...propertyDetails,
        [field]: newValue,
      });

      // Sync purchase price with Financial Details when it changes
      if (field === "purchasePrice") {
        setFinancialDetails((prev) => ({
          ...prev,
          purchasePrice: newValue,
        }));
      }
    };

  // Update values when API data changes
  useEffect(() => {
    // Only update if we have predictions AND user hasn't manually adjusted values
    if (!hasUserAdjustedValues) {
      // Only update if we have both occupancy and daily rate from calculator state
      if (calculatorState.occupancy && calculatorState.averageDailyRate) {
        const newOccupancy = Math.round(calculatorState.occupancy * 100);
        const newDailyRate = Math.round(calculatorState.averageDailyRate);

        // Update both sliders
        setSliderOccupancyRate(newOccupancy);
        setSliderAvgDailyRate(newDailyRate);

        // Update metrics with both new values
        updateMetrics(newOccupancy, newDailyRate);
      }
      // Handle cases where only one value is available
      else if (calculatorState.occupancy) {
        const newOccupancy = Math.round(calculatorState.occupancy * 100);
        setSliderOccupancyRate(newOccupancy);
        updateMetrics(newOccupancy, sliderAvgDailyRate);
      } else if (calculatorState.averageDailyRate) {
        const newDailyRate = Math.round(calculatorState.averageDailyRate);
        setSliderAvgDailyRate(newDailyRate);
        updateMetrics(sliderOccupancyRate, newDailyRate);
      }
    }
  }, [
    calculatorState.occupancy,
    calculatorState.averageDailyRate,
    updateMetrics,
    sliderOccupancyRate,
    sliderAvgDailyRate,
    hasUserAdjustedValues,
  ]);

  useEffect(() => {
    if (location.state?.propertyDetails) {
      const { address, bedrooms, bathrooms, guests, purchasePrice, lat, lng } =
        location.state.propertyDetails;

      setPropertyDetails((prev) => ({
        ...prev,
        address,
        bedrooms,
        bathrooms,
        guests,
        purchasePrice,
        lat,
        lng,
      }));

      setFinancialDetails((prev) => ({
        ...prev,
        purchasePrice,
      }));

      setSearchInput(address);
    }
  }, [location.state]);

  useEffect(() => {
    if (
      propertyDetails.lat != null &&
      propertyDetails.lng != null &&
      propertyDetails.bedrooms != null &&
      propertyDetails.bathrooms != null &&
      propertyDetails.guests != null &&
      financialDetails.purchasePrice != null &&
      financialDetails.purchasePrice > 0
    ) {
      if (isFirstLoadRef.current) {
        fetchPredictionImmediately(propertyDetails);
        isFirstLoadRef.current = false;
      } else {
        debouncedFetchPrediction(propertyDetails);
      }
    }
  }, [propertyDetails, debouncedFetchPrediction, fetchPredictionImmediately]);

  // Add a new useEffect to sync amounts when revenue or purchase price changes
  useEffect(() => {
    const revenue = financialDetails.rentalRevenue || 0;
    const purchasePrice = financialDetails.purchasePrice || 0;

    // Calculate all amounts based on their respective percentages
    const managementFeeAmount =
      (financialDetails.managementFee / 100) * revenue;
    const channelFeeAmount = (financialDetails.channelFee / 100) * revenue;
    const propertyTaxAmount =
      (financialDetails.propertyTax / 100) * purchasePrice; // This is annual amount
    const otherExpensesAmount =
      (financialDetails.otherExpenses / 100) * revenue;
    const downPaymentAmount =
      (financialDetails.downPayment / 100) * purchasePrice;
    const loanAmount = purchasePrice - downPaymentAmount;

    setFinancialDetails((prev) => ({
      ...prev,
      managementFeeAmount: Number(managementFeeAmount.toFixed(2)),
      channelFeeAmount: Number(channelFeeAmount.toFixed(2)),
      propertyTaxAmount: Number(propertyTaxAmount.toFixed(2)), // Annual property tax
      otherExpensesAmount: Number(otherExpensesAmount.toFixed(2)),
      downPaymentAmount: Number(downPaymentAmount.toFixed(2)),
      loanAmount: Number(loanAmount.toFixed(2)),
    }));
  }, [financialDetails.rentalRevenue, financialDetails.purchasePrice]);

  // Add a new useEffect to calculate financial metrics
  useEffect(() => {
    // Calculate Annual Revenue
    const annualRevenue = (financialDetails.rentalRevenue || 0) * 12;

    // Calculate Annual Operating Expenses
    const annualOperatingExpenses =
      12 *
        ((financialDetails.managementFeeAmount || 0) +
          (financialDetails.channelFeeAmount || 0) +
          (financialDetails.maintenance || 0) +
          (financialDetails.insurance || 0) +
          (financialDetails.utilities || 0) +
          (financialDetails.hoaFees || 0) +
          (financialDetails.otherExpensesAmount || 0)) +
      (financialDetails.propertyTaxAmount || 0); // Property tax is already an annual amount

    // Calculate Annual Loan Payment (if not cash only)
    let annualLoanPayment = 0;
    if (!financialDetails.isCashOnly && financialDetails.useLoan) {
      const monthlyRate = (financialDetails.interestRate || 0) / 100 / 12;
      const numberOfPayments = (financialDetails.loanTerm || 30) * 12;
      const loanAmount = financialDetails.loanAmount || 0;

      if (monthlyRate > 0) {
        const monthlyPayment =
          (loanAmount * (monthlyRate * (1 + monthlyRate) ** numberOfPayments)) /
          ((1 + monthlyRate) ** numberOfPayments - 1);
        annualLoanPayment = monthlyPayment * 12;
      }
    }

    // Calculate Total Cash Investment
    const totalCashInvestment =
      (financialDetails.downPaymentAmount || 0) +
      ((financialDetails.closingCosts || 0) / 100) *
        (financialDetails.purchasePrice || 0) +
      (financialDetails.immediateRepairs || 0) +
      (financialDetails.furnishingCosts || 0) +
      (financialDetails.initialExpenses || 0);

    // Calculate Annual Profit
    const annualProfit =
      annualRevenue - annualOperatingExpenses - annualLoanPayment;

    // Calculate Cap Rate (based on NOI)
    const netOperatingIncome = annualRevenue - annualOperatingExpenses;
    const capRate =
      (netOperatingIncome / (financialDetails.purchasePrice || 1)) * 100;

    // Calculate Cash on Cash Return
    const cashOnCash = (annualProfit / (totalCashInvestment || 1)) * 100;

    // Update calculated metrics
    setCalculatedMetrics((prev) => ({
      ...prev,
      annualProfit: Math.round(annualProfit),
      capRate: Math.round(capRate * 100) / 100,
      cashOnCash: Math.round(cashOnCash * 100) / 100,
    }));
  }, [financialDetails]);

  // Calculate metrics based on appreciation rates
  const calculateMetrics = ({ property: propertyRate }) => {
    // Get base values
    const basePropertyValue = financialDetails.purchasePrice || 0;
    const baseAnnualRevenue = (financialDetails.rentalRevenue || 0) * 12;

    // Calculate monthly expenses
    const monthlyExpenses =
      (financialDetails.managementFeeAmount || 0) +
      (financialDetails.channelFeeAmount || 0) +
      (financialDetails.propertyTaxAmount || 0) / 12 + // Convert annual to monthly
      (financialDetails.insurance || 0) +
      (financialDetails.hoaFees || 0) +
      (financialDetails.utilities || 0) +
      (financialDetails.maintenance || 0) +
      (financialDetails.otherExpensesAmount || 0);

    // Calculate monthly mortgage payment
    let monthlyMortgage = 0;
    if (!financialDetails.isCashOnly && financialDetails.useLoan) {
      const monthlyRate = (financialDetails.interestRate || 0) / 100 / 12;
      const numberOfPayments = (financialDetails.loanTerm || 30) * 12;
      const loanAmount = financialDetails.loanAmount || 0;

      if (monthlyRate > 0) {
        monthlyMortgage =
          (loanAmount * monthlyRate * (1 + monthlyRate) ** numberOfPayments) /
          ((1 + monthlyRate) ** numberOfPayments - 1);
      }
    }

    const baseAnnualExpenses = monthlyExpenses * 12;
    const baseAnnualMortgage = monthlyMortgage * 12;

    // Calculate first year appreciation for property only
    const appreciatedPropertyValue =
      basePropertyValue * (1 + propertyRate / 100);

    // Calculate new profit (revenue doesn't appreciate in main financials)
    const annualProfit =
      baseAnnualRevenue - baseAnnualExpenses - baseAnnualMortgage;

    // Calculate new cap rate
    const netOperatingIncome = baseAnnualRevenue - baseAnnualExpenses;
    const capRate = (netOperatingIncome / appreciatedPropertyValue) * 100;

    // Calculate new cash on cash return
    const totalCashInvestment =
      (financialDetails.downPaymentAmount || 0) +
      ((financialDetails.closingCosts || 0) / 100) * basePropertyValue +
      (financialDetails.immediateRepairs || 0) +
      (financialDetails.furnishingCosts || 0) +
      (financialDetails.initialExpenses || 0);

    const cashOnCash = (annualProfit / totalCashInvestment) * 100;

    return {
      ...calculatedMetrics,
      annualProfit: Math.round(annualProfit),
      capRate: Math.round(capRate * 100) / 100,
      cashOnCash: Math.round(cashOnCash * 100) / 100,
    };
  };

  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        background:
          "linear-gradient(180deg, rgb(235, 245, 255) 0%, rgba(250, 250, 250, 0.8) 25%, rgba(250, 250, 250, 0.9) 50%, rgba(255, 255, 255, 1) 100%)",
      }}
    >
      <HeroSection />

      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Grid container spacing={4}>
          {/* Property Overview */}
          <Grid item xs={12}>
            <PropertyOverview
              propertyDetails={propertyDetails}
              onInputChange={handleInputChange}
              predictions={predictions}
              searchInput={searchInput}
              handlePlaceSelect={onPlaceSelect}
              handleAddressSearch={handleAddressSearch}
              calculatedMetrics={calculatedMetrics}
              onCalculate={handlePropertyDetailsCalculate}
            />
          </Grid>

          {/* Seasonality Chart */}
          <Grid item xs={12}>
            <PropertyVariables
              data={seasonalityData}
              occupancyRate={sliderOccupancyRate}
              avgDailyRate={sliderAvgDailyRate}
              onOccupancyChange={handleOccupancyChange}
              onDailyRateChange={handleDailyRateChange}
              predictedOccupancy={calculatorState.occupancy * 100}
              predictedDailyRate={calculatorState.averageDailyRate}
              comparables={calculatorState.similarListings}
              onReset={() => setHasUserAdjustedValues(false)}
            />
          </Grid>

          {/* Financial Details & Key Performance Metrics Combined */}
          <Grid item xs={12}>
            <Paper
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                backdropFilter: "blur(10px)",
                p: 3,
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  mb: 3,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                Financials
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    const currentPurchasePrice = financialDetails.purchasePrice;
                    setFinancialDetails({
                      purchasePrice: currentPurchasePrice,
                      downPayment: 20,
                      downPaymentAmount: currentPurchasePrice * 0.2,
                      interestRate: 6.5,
                      loanAmount: currentPurchasePrice * 0.8,
                      loanTerm: 30,
                      rentalRevenue: 0,
                      managementFee: 20,
                      managementFeeAmount: 0,
                      channelFee: 3,
                      channelFeeAmount: 0,
                      propertyTax: 1,
                      propertyTaxAmount: currentPurchasePrice * 0.01,
                      cleaningFees: 0,
                      maintenance: 0,
                      insurance: 0,
                      utilities: 0,
                      hoaFees: 0,
                      otherExpenses: 0,
                      otherExpensesAmount: 0,
                      closingCosts: 0,
                      immediateRepairs: 0,
                      furnishingCosts: 0,
                      initialExpenses: 0,
                      isCashOnly: false,
                      useLoan: true,
                    });
                  }}
                  sx={{
                    textTransform: "none",
                    minWidth: "unset",
                    px: 2,
                  }}
                >
                  Reset
                </Button>
              </Typography>

              {/* Performance Metrics Cards at the top */}
              <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={4}>
                  <MetricCard
                    title="Annual Profit"
                    value={
                      <NumberFormat
                        value={calculatedMetrics.annualProfit || 0}
                        displayType="text"
                        thousandSeparator
                        prefix="$"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MetricCard
                    title="Cap Rate"
                    value={`${calculatedMetrics.capRate || 0}%`}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MetricCard
                    title="Cash on Cash"
                    value={`${calculatedMetrics.cashOnCash || 0}%`}
                  />
                </Grid>
              </Grid>

              {/* Financial Details taking full width */}
              <Box>
                <FinancialDetails
                  financialDetails={financialDetails}
                  onFinancialDetailsChange={handleFinancialDetailsChange}
                  disableCard
                  sx={{
                    "& .MuiAccordion-root": {
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.02)",
                        cursor: "pointer",
                      },
                      "& .MuiAccordionSummary-root": {
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                        "& .MuiAccordionSummary-expandIconWrapper": {
                          color: "primary.main",
                          transition: "all 0.2s ease",
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                        },
                      },
                      "& .MuiAccordionSummary-content": {
                        "& .MuiTypography-root": {
                          color: "primary.main",
                          fontWeight: 500,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>

          {/* Property Comparables */}
          {calculatorState.similarListings &&
            calculatorState.similarListings.length > 0 && (
              <Grid item xs={12}>
                <PropertyComparables
                  comparables={calculatorState.similarListings}
                />
              </Grid>
            )}

          {/* Investment Projection */}
          <Grid item xs={12}>
            <InvestmentProjection
              data={[financialDetails]}
              onAppreciationChange={calculateMetrics}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AirbnbCalculator;
