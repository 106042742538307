import { useState, useCallback } from "react";

let autocompleteInstance = null;
let geocoderInstance = null;

export default function useAddressAutocomplete() {
  const [predictions, setPredictions] = useState([]);
  const [loadError, setLoadError] = useState(null);

  const handleAddressSearch = useCallback(async (input) => {
    if (!input) {
      return;
    }

    if (!window.google?.maps?.places?.AutocompleteService) {
      setLoadError("Google Maps API not loaded");
      return;
    }

    if (autocompleteInstance == null) {
      autocompleteInstance =
        new window.google.maps.places.AutocompleteService();
    }
    const service = autocompleteInstance;
    try {
      const results = await new Promise((resolve, reject) => {
        service.getPlacePredictions(
          {
            input,
            types: ["address"],
          },
          (predictionResults, status) => {
            if (status === "OK") {
              resolve(predictionResults);
            } else {
              reject(status);
            }
          }
        );
      });
      setPredictions(results || []);
      setLoadError(null);
    } catch (error) {
      console.error("Error fetching place predictions:", error);
      setPredictions([]);
      setLoadError(error.message);
    }
  }, []);

  const handlePlaceSelect = useCallback(async (placeId) => {
    if (!placeId) {
      return null;
    }

    if (!window.google?.maps?.Geocoder) {
      setLoadError("Google Maps API not loaded");
      return null;
    }

    if (geocoderInstance == null) {
      geocoderInstance = new window.google.maps.Geocoder();
    }
    const geocoder = geocoderInstance;

    try {
      const result = await new Promise((resolve, reject) => {
        geocoder.geocode({ placeId }, (results, status) => {
          if (status === "OK") {
            resolve(results[0]);
          } else {
            reject(new Error(`Geocoder failed: ${status}`));
          }
        });
      });
      setLoadError(null);
      return {
        formattedAddress: result.formatted_address,
        location: result.geometry.location,
      };
    } catch (error) {
      console.error("Error fetching place details:", error);
      setLoadError(error.message);
      return null;
    }
  }, []);

  return {
    predictions,
    handleAddressSearch,
    handlePlaceSelect,
    loadError,
  };
}
