import React from "react";
import { Box } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import SEO from "./SEO";
import tos from "../assets/legal/terms-of-service.md";
import { markDownStyles } from "./cssvars";
import withErrorBoundary from "./error/withErrorBoundary";

const TermsOfService = () => {
  return (
    <>
      <SEO
        title="Terms of Service"
        description="Read AirROI's terms of service to understand the rules and guidelines for using our Airbnb analytics, market research, and dynamic pricing tools."
        keywords="terms of service, user agreement, legal terms, service conditions, usage terms"
        type="website"
        url="https://www.airroi.com/tos"
        canonical="https://www.airroi.com/tos"
      />
      <Box
        sx={{
          ...markDownStyles,
          backgroundColor: "#fafafa",
          fontFamily: `"Lato", "sans-serif"`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "888px",
            px: { xs: 2, md: 4 },
            pb: { xs: 1, md: 8 },
          }}
        >
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{tos}</ReactMarkdown>
        </Box>
      </Box>
    </>
  );
};

export default withErrorBoundary(TermsOfService);
