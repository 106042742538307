import React from "react";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import ErrorBoundaryRender from "./ErrorBoundaryRender";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Log errors to sentry only in production environment
    // eslint-disable-next-line no-undef
    if (ENV === "production") {
      // note: without this production mode would not send errors to Sentry, in development mode it does not need this line because React intentionally makes error trigger twice which makes the second time uncaught and bubble up and gets seen by Sentry thus sending it automatically
      Sentry.captureException(error, { extra: errorInfo });
    }
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorBoundaryRender error={error} />;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
