import React from "react";
import { Box, IconButton, Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const CustomZoomControl = ({ onZoomIn, onZoomOut }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 20,
        right: 10,
        backgroundColor: "white",
        borderRadius: "4px",
        boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <IconButton
        onClick={onZoomIn}
        size="small"
        aria-label="Zoom in"
        disableRipple
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
          padding: "4px",
        }}
      >
        <AddIcon sx={{ color: "#555", fontSize: "1.2rem" }} />
      </IconButton>
      <Divider sx={{ margin: 0 }} />
      <IconButton
        onClick={onZoomOut}
        size="small"
        aria-label="Zoom out"
        disableRipple
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
          padding: "4px",
        }}
      >
        <RemoveIcon sx={{ color: "#555", fontSize: "1.2rem" }} />
      </IconButton>
    </Box>
  );
};

export default CustomZoomControl;
