import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import AirbnbListingCard from "./AirbnbListingCard";
import {
  AIRBNB_LISTING_CARD,
  FOR_SALE_LISTING_CARD,
} from "../constants/listingCardTypes";
import ForSaleListingCard from "./ForSaleListingCard";
import { fetchAirbnbListingsDetails } from "../actions/airbnbListingsActions";
import { useMediaQuery, useTheme } from "@mui/system";
import { Grid } from "@mui/material";

const Listings = ({
  listings,
  listingsDetails,
  cardRefs,
  listingsPerPage,
  selectedListingIdx,
  setSelectedListingIdx,
  listingCardType,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const cols = useMediaQuery(theme.breakpoints.down("xl")) ? 1 : 2;

  const [page, setPage] = useState(1); // IMPORTANT: the page starts at 1

  const start = (page - 1) * listingsPerPage;
  const end = start + listingsPerPage; // exclusive
  const totalPages = Math.ceil(listings.length / listingsPerPage);
  const listingsToShow = useMemo(
    () => listings.slice(start, end),
    [start, end]
  );

  const listingsToShowWithDetails = listingsToShow
    .map((listing) => listing.id)
    .map((id) => listingsDetails[id])
    .filter(Boolean);

  const queryCurrency = useSelector(
    (state) => state.airbnbListings.query.currency
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedListingIdx != null) {
      const toPage = Math.trunc(selectedListingIdx / listingsPerPage) + 1;
      setPage(toPage);
    }
  }, [selectedListingIdx]);

  useEffect(() => {
    const idsWithoutDetail = listingsToShow
      .filter((listing) => !listing.detail && !(listing.id in listingsDetails))
      .map((listing) => listing.id);

    if (idsWithoutDetail.length > 1) {
      dispatch(fetchAirbnbListingsDetails(idsWithoutDetail, queryCurrency));
    }
  }, [listingsToShow, queryCurrency]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <ImageList sx={{ margin: isMobile ? "0px 16px" : 0 }} cols={cols}>
        {listingsToShowWithDetails.map((listing, idx) => {
          return (
            <ImageListItem
              key={idx}
              ref={cardRefs[idx]}
              onMouseEnter={() => {
                setSelectedListingIdx((page - 1) * listingsPerPage + idx);
              }}
              onMouseLeave={() => {
                setSelectedListingIdx(null);
              }}
            >
              {listingCardType === AIRBNB_LISTING_CARD ? (
                listing.detail && (
                  <AirbnbListingCard
                    listing={listing}
                    listingDetail={listingsDetails[listing.id]}
                    isSelected={
                      selectedListingIdx != null
                        ? idx === selectedListingIdx % listingsPerPage
                        : false
                    }
                    isMapOverlay={false}
                    setSelectedListingIdx={setSelectedListingIdx}
                    fetchDetails
                  />
                )
              ) : (
                <ForSaleListingCard
                  listing={listing}
                  isSelected={
                    selectedListingIdx != null
                      ? idx === selectedListingIdx % listingsPerPage
                      : false
                  }
                />
              )}
            </ImageListItem>
          );
        })}
      </ImageList>
      <Box
        display="flex"
        p={1}
        justifyContent="center"
        sx={{ marginTop: "auto" }}
      >
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={(event, pageNumber) => {
            setPage(pageNumber);
          }}
          page={page}
        />
      </Box>
    </Box>
  );
};

Listings.propTypes = {
  listings: PropTypes.arrayOf(PropTypes.any).isRequired,
  listingsDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  cardRefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  listingsPerPage: PropTypes.number.isRequired,
  selectedListingIdx: PropTypes.number.isRequired,
  setSelectedListingIdx: PropTypes.func.isRequired,
  listingCardType: PropTypes.oneOf([AIRBNB_LISTING_CARD, FOR_SALE_LISTING_CARD])
    .isRequired,
};

export default Listings;
