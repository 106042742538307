import React from "react";
import {
  Typography,
  Box,
  Container,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import SEO from "./SEO";
import withErrorBoundary from "./error/withErrorBoundary";

const styles = {
  container: {
    mx: { xs: 2, sm: 4, md: 10, lg: 20 },
    my: { xs: 8, md: 16 },
  },
  header: {
    textAlign: "center",
    mb: { xs: 4, md: 8 },
  },
  faqContainer: {
    maxWidth: "800px",
    mx: "auto",
    mt: { xs: 4, md: 8 },
  },
  faqItem: {
    mb: 6, // Increased vertical margin between question-answer pairs
  },
  question: {
    mb: 2,
    color: "primary.main", // Using primary color from the theme
  },
  answer: {
    "& img": {
      maxWidth: "100%",
      height: "auto",
      my: 2,
    },
    "& .chart-container": {
      width: "100%",
      height: "300px",
      my: 2,
    },
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

const faqData = [
  {
    question: "Why is AirROI free?",
    answer: (
      <Typography paragraph>
        We provide AirROI as a free alternative to existing products which
        charge exorbitant fees for people who want to access analytics and
        automatic pricing tools. Our mission is to empower people by making
        high-quality analytics and tools accessible to everyone, regardless of
        their budget.
      </Typography>
    ),
  },
  {
    question: "How accurate is your data?",
    answer: (
      <>
        <Typography paragraph>
          We achieve overall 95%+ revenue accuracy as a baseline. To accomplish
          this, we process a vast trove of 20+ million properties and 10+ years
          of Airbnb data collected from multiple sources. Our state-of-the-art
          AI/ML algorithms and models process this extensive dataset to achieve
          such fantastic results. We don't stop there; we continuously refine
          our algorithms to ensure the most accurate and up-to-date information.
        </Typography>
        <Typography paragraph>
          For more details, please see our blog post on{" "}
          <Link
            component={RouterLink}
            to="/blog/data-accuracy"
            sx={styles.link}
          >
            data accuracy
          </Link>
          .
        </Typography>
      </>
    ),
  },
  {
    question:
      "How far back can I see historical data, and how far into the future can I forecast?",
    answer: (
      <Typography paragraph>
        Our time range filter allows you to dial back the timeline up to 10
        years to see historical data. For forecasting, we provide insights up to
        one year into the future.
      </Typography>
    ),
  },
  {
    question: "How does the Dynamic Pricing feature work?",
    answer: (
      <>
        <Typography paragraph>
          Our Dynamic Pricing feature uses advanced algorithms to calculate the
          optimal price for your property, maximizing your revenue potential. By
          analyzing historical data and real-time market signals, it takes into
          account various factors such as seasonality, demand fluctuations, day
          of the week, locality, and many more.
        </Typography>
        <Typography paragraph>Key features include:</Typography>
        <ol>
          <li>
            Multi-account support: Connect and manage multiple listings from
            various Airbnb accounts within our system.
          </li>
          <li>
            Automatic price syncing: Enable auto-sync to periodically update
            your Airbnb listing prices based on our recommendations.
          </li>
          <li>
            Flexible user options:
            <ul>
              <li>
                Hands-off approach: Utilize our baseline price recommendations
                for effortless optimization.
              </li>
              <li>
                Advanced customization: Fine-tune your pricing strategy with
                adjustable rules and settings, including:
                <ul>
                  <li>Last-minute booking rules</li>
                  <li>Far-future pricing adjustments</li>
                  <li>Orphan day handling</li>
                  <li>Day-of-week pricing variations</li>
                  <li>Occupancy-based price modifications</li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>
      </>
    ),
  },
  {
    question:
      "I want to explore business collaboration, have a data request, or have unique needs. How do I get started?",
    answer: (
      <Typography paragraph>
        We offer flexible Enterprise Solutions tailored to your unique business
        needs. Please{" "}
        <Link component={RouterLink} to="/contact" sx={styles.link}>
          contact us
        </Link>{" "}
        to discuss how we can provide customized property and market level
        intelligence for your specific requirements.
      </Typography>
    ),
  },
  {
    question:
      "I've encountered a bug that's preventing me from using a feature. What should I do?",
    answer: (
      <Typography paragraph>
        Please{" "}
        <Link component={RouterLink} to="/contact" sx={styles.link}>
          contact our support
        </Link>{" "}
        with details about the bug you've encountered.
      </Typography>
    ),
  },
  {
    question: "I have an idea or a feature request. How can I submit it?",
    answer: (
      <Typography paragraph>
        Please don't hesitate to share with us through our{" "}
        <Link component={RouterLink} to="/contact" sx={styles.link}>
          support channels
        </Link>
        .
      </Typography>
    ),
  },
];

const FAQ = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <SEO
        title="Frequently Asked Questions"
        description="Find answers to common questions about AirROI's Airbnb analytics, market research tools, revenue calculator, and dynamic pricing solutions."
        keywords="Airbnb FAQ, rental analytics help, pricing calculator FAQ, market research questions, AirROI support"
        type="website"
        url="https://www.airroi.com/faq"
        canonical="https://www.airroi.com/faq"
      />
      <Box sx={styles.container}>
        <Container maxWidth="xl" disableGutters>
          <Box sx={styles.header}>
            <Typography variant={isMobile ? "h5" : "h4"} sx={{ mb: 2 }}>
              Frequently Asked Questions
            </Typography>
          </Box>

          <Box sx={styles.faqContainer}>
            {faqData.map((faq, index) => (
              <Box key={index} sx={styles.faqItem}>
                <Typography variant="h6" sx={styles.question}>
                  {faq.question}
                </Typography>
                <Box sx={styles.answer}>{faq.answer}</Box>
              </Box>
            ))}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default withErrorBoundary(FAQ);
