import { ENTIRE_HOME, PRIVATE_ROOM, SHARED_ROOM } from "../constants/roomTypes";
import { SORT_BY_DEFAULT } from "../constants/sortOrders";
import { defaultFilterValues } from "../constants/defaultValues";
import {
  DAY_OF_WEEK,
  FAR_FUTURE,
  LAST_MINUTE,
  OCCUPANCY_ADJUSTMENT,
  ORPHAN_DAY,
} from "../constants/dynamicPricing";

const emptyRules = {
  [LAST_MINUTE]: {
    type: LAST_MINUTE,
    mode: "Default",
    adjustmentType: "Gradual",
    value: "",
    adjustmentSubType: "Discount",
    days: "",
  },
  [FAR_FUTURE]: {
    type: FAR_FUTURE,
    mode: "Default",
    adjustmentType: "Gradual",
    value: "",
    adjustmentSubType: "Premium",
    days: "",
  },
  [ORPHAN_DAY]: {
    type: ORPHAN_DAY,
    mode: "Default",
    adjustmentType: "Flat",
    value: "",
    adjustmentSubType: "Discount",
    days: "",
  },
  [DAY_OF_WEEK]: {
    type: DAY_OF_WEEK,
    sunday: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
  },
  [OCCUPANCY_ADJUSTMENT]: {
    type: OCCUPANCY_ADJUSTMENT,
    mode: "Default",
    leadTimes: [
      ["", ""],
      ["", ""],
      ["", ""],
    ],
    values: [["", ["", "", ""]]], // it's a object.entries list, needs to be converted to Object for invoking server rules API
  },
};

export const defaultStoreValues = {
  toast: {
    level: "", // error, info, warning, success
    message: "",
  },
  airbnbListings: {
    demo: false,
    ready: false,
    nativeCurrency: null,
    listings: [],
    totalListings: 0,
    listingsDetails: [],
    aggregates: { months: [] },
    latitude: null,
    longitude: null,
    query: {
      location: {
        countryCode: null,
        state: null,
        city: null,
        neighborhood: null,
        subdivision: null,
      },
      filters: {
        dateRange: defaultFilterValues.dateRange,
        propertyType: defaultFilterValues.propertyType,
        bedrooms: defaultFilterValues.bedrooms,
        guests: defaultFilterValues.guests,
        advanced: defaultFilterValues.advanced,
      },
      sortOrder: SORT_BY_DEFAULT,
      currency: null, // server picks the currency based on region if null
    },
  },
  dynamicPricing: {
    dashboard: {
      ready: false,
      query: "",
      listings: [],
    },
    accounts: [],
    // if we want to support multi-calendar view in the future, just change this to listings and key off listingId (and channel)
    listing: {
      ready: false,
      selectedMonth: null,
      autoSync: false,
      minPrice: null,
      basePrice: null,
      maxPrice: null,
      rates: [],
      overrides: [],
      listingRules: { ...emptyRules },
      appliedRules: {},
      defaultRules: { ...emptyRules },
    },
    // not in-use currently, but will be necessary when supporting different rule tiers
    rules: {
      account: {},
      groups: {},
      listings: {},
    },
  },
  calculator: {
    ready: false,
    occupancy: null,
    averageDailyRate: null,
    monthlyRevenueDistributions: [],
    similarListings: [],
  },
};

export default defaultStoreValues;
