/* eslint-disable react/prop-types */

/* eslint-disable react/forbid-prop-types */
import { keyframes } from "@mui/system";

import React, { memo } from "react";
import { OverlayView, OverlayViewF } from "@react-google-maps/api";
import Box from "@mui/material/Box";
import Listings from "./Listings";
import AirbnbListingCard from "./AirbnbListingCard";
import { AIRBNB_LISTING_CARD } from "../constants/listingCardTypes";

import MarketSummarySection from "./MarketSummarySection";

export const mapAggregationsToMarketSummary = (aggregates) => {
  // only use last 12 months data for market summary cards
  return aggregates.months.slice(-12).map((month) => {
    const { date, priceAvg, occupancyAvg, revenueAvg } = month;
    return {
      date,
      priceAvg,
      occupancyAvg,
      revenueAvg,
    };
  });
};

const pulseAnimation = keyframes`
  0% {
    width: 16px;
    height: 16px;
    opacity: 0.6;
  }
  100% {
    width: 48px;
    height: 48px;
    opacity: 0;
  }
`;

export const Aura = memo(({ listing }) => {
  if (!listing) return null;

  return (
    <OverlayViewF
      position={{
        lat: listing.latitude,
        lng: listing.longitude,
      }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      {[0, 0.1, 0.2].map((delay, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            transform: "translate(-50%, -50%)",
            borderRadius: "50%",
            backgroundColor: "primary.main",
            animation: `${pulseAnimation} 1.5s ease-out infinite`,
            animationDelay: `${delay}s`,
            opacity: 0.6,
          }}
        />
      ))}
    </OverlayViewF>
  );
});

export const ListingOverlay = ({
  selectedListing,
  selectedListingDetail,
  hoveredListing,
  hoveredListingDetail,
  setSelectedListingIdx,
}) => {
  const renderOverlay = (listing, isSelected) => {
    if (!listing) return null;

    return (
      <OverlayViewF
        key={`overlay-${listing.id}-${isSelected ? "selected" : "hovered"}`}
        position={{
          lat: listing.latitude,
          lng: listing.longitude,
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <Box
          sx={{
            width: "250px",
            position: "absolute",
            left: "-125px",
            top: "20px",
          }}
        >
          <AirbnbListingCard
            listing={listing}
            listingDetail={
              isSelected ? selectedListingDetail : hoveredListingDetail
            }
            isSelected={false}
            isMapOverlay
            setSelectedListingIdx={setSelectedListingIdx}
            fetchDetails
          />
        </Box>
      </OverlayViewF>
    );
  };

  return (
    <>
      {renderOverlay(selectedListing, true)}
      {renderOverlay(hoveredListing, false)}
    </>
  );
};

export const ListingsPane = ({
  isMobile,
  listingsWidth,
  mobileMapOnlyMode,
  aggregates,
  listings,
  listingsDetails,
  cardRefs,
  listingsPerPage,
  selectedListingIdx,
  setSelectedListingIdx,
  wideScreen,
}) => {
  return (
    <Box
      sx={{
        width: isMobile ? "100%" : `${listingsWidth}px`,
        flexShrink: 0,
        display: isMobile && mobileMapOnlyMode ? "none" : "block",
      }}
    >
      {isMobile && !mobileMapOnlyMode && (
        <MarketSummarySection
          months={mapAggregationsToMarketSummary(aggregates)}
        />
      )}
      <Listings
        listings={listings}
        listingsDetails={listingsDetails}
        cardRefs={cardRefs}
        listingsPerPage={listingsPerPage}
        selectedListingIdx={selectedListingIdx}
        setSelectedListingIdx={setSelectedListingIdx}
        listingCardType={AIRBNB_LISTING_CARD}
        wideScreen={wideScreen}
      />
    </Box>
  );
};

export const mapStyles = [
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry",
    stylers: [
      {
        color: "#B0DDBC",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#B0DDBC",
      },
    ],
  },

  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#FDE59C",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#E1EBFA",
        // not using #9DC0F8 even though it is the classic google maps water color, because it is too close with our property dot color
      },
    ],
  },
];
